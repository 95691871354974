import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class CategoryDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.categories;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { categoryKeyFilter } = params.filter;
        const query = categoryKeyFilter
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  keys: categoryKeyFilter,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.categories.map((category: any) => ({
                                ...category,
                                id: category.key,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.key } });
                })
                .catch(reject);
        });
    }

    getMany(params: DataProviderParams): Promise<any> {
        let keysQuery = '';
        for (const id of params.ids) {
            keysQuery = keysQuery.concat(keysQuery === '' ? id : `,${id}`);
        }

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?keys=${keysQuery}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.categories.map((category: any) => ({
                                ...category,
                                id: category.key,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    create(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            let meta = params.data.meta;
            try {
                meta = JSON.parse(params.data.meta);
            } catch (error) {}

            this.getHttpClient('', {
                method: 'POST',
                body: JSON.stringify({ ...params.data, meta }),
            })
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.key } });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        if (params?.data?.image) {
            const formData = new FormData();

            for (const key in params.data.image) {
                formData.append('image', params.data.image[key]);
            }

            new Promise((resolve, reject) => {
                this.getHttpClient(`${params.id}/category-icon`, {
                    method: 'POST',
                    body: formData,
                })
                    .then((response: any) => {
                        resolve({ data: {} });
                    })
                    .catch(reject);
            });
        }
        return new Promise((resolve, reject) => {
            let meta = params.data.meta;
            try {
                meta = JSON.parse(params.data.meta);
            } catch (error) {}

            this.getHttpClient(`${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    displayName: params.data.displayName,
                    meta,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }

    delete(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'DELETE',
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }
}

export default new CategoryDataProvider();
