import React from 'react';
import { Create, SimpleForm, TextInput, PasswordInput } from 'react-admin';

export const UserCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" label="Name" />
            <TextInput type="email" source="email" label="Email" />
            <TextInput source="phoneNumber" label="Phone Number" />
            <TextInput source="country" label="Country" />
            <PasswordInput source="password" label="Password" />
        </SimpleForm>
    </Create>
);
