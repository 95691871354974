import React from 'react';
import { Create, SimpleForm, TextInput, required } from 'react-admin';

export const CategoryCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="key" label="Key" validate={[required()]} />
            <TextInput source="displayName" label="Display Name" validate={[required()]} />
            <TextInput
                multiline
                resettable
                source="meta"
                label="Meta Data (JSON)"
                defaultValue='{
                    "bgColor": "",
                    "bgImage": "serw/static-app-contents/categories/",
                    "iconImg": "serw/static-app-contents/categories/",
                    "shadowColor": ""
                }'
                validate={[required()]}
            />
        </SimpleForm>
    </Create>
);
