import React from 'react';
import { List, Datagrid, TextField, Filter, SearchInput, SelectInput } from 'react-admin';
import { Role } from '../../config/config';
import { PageResults } from './../../pageResults/PageResults';

export const AdminList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        hasCreate={true}
        perPage={25}
        pagination={<PageResults />}
        filters={
            <Filter>
                <SearchInput placeholder="User ID" source="userId" alwaysOn />
                <SearchInput placeholder="Username" source="username" alwaysOn />
                <SelectInput
                    source="role"
                    choices={[
                        { id: Role.ROOT, name: Role.ROOT },
                        { id: Role.ADMIN, name: Role.ADMIN },
                    ]}
                    alwaysOn
                />
            </Filter>
        }
        sort={{ field: 'userId', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="userId" label="User ID" />
            <TextField source="username" label="Username" sortable={false} />
            <TextField source="role" label="Role" sortable={false} />
        </Datagrid>
    </List>
);
