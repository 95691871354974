import React from 'react';
import { List, Datagrid, TextField, NumberField, FunctionField } from 'react-admin';
import { PageResults } from './../../pageResults/PageResults';

export const CategoryList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        hasCreate={true}
        pagination={<PageResults />}
        perPage={25}
    >
        <Datagrid rowClick="show">
            <TextField source="key" label="Key" sortable={false} />
            <TextField source="displayName" label="Display Name" sortable={false} />
            <NumberField source="serviceCount" label="Services Count" sortable={false} />
            <FunctionField
                label="Meta Data"
                render={(record: any) => {
                    if (!record || !record.meta) {
                        return '';
                    }
                    try {
                        return JSON.stringify(record.meta);
                    } catch (error) {}
                    return '';
                }}
                sortable={false}
            />
        </Datagrid>
    </List>
);
