import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class DeviceDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.devices;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { userId } = params.filter;
        let { field, order } = params.sort;
        if (field === 'userId') {
            field = 'userName';
        }

        const query = {
            skip: (page - 1) * perPage,
            take: perPage,
            userId,
            sortKey: field || undefined,
            sortOrder: order || undefined,
        };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.devices.map((device: any) => ({
                                ...device,
                                id: device.pushNotificationId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.pushNotificationId } });
                })
                .catch(reject);
        });
    }
}

export default new DeviceDataProvider();
