import React from 'react';
import authProvider from '../../auth/authProvider';
import { ResourceName } from '../../config/config';
import { AdminEdit } from '../admins/AdminEdit';

const Profile = (props: any) => {
    const currentId = authProvider.getCurrentId();

    return <AdminEdit {...props} id={currentId} resource={ResourceName.admins} basePath="/profile" />;
};

export default Profile;
