import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { PageResults } from './../../pageResults/PageResults';

export const DashboardList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        hasCreate={false}
        pagination={<PageResults />}
        perPage={25}
    >
        <Datagrid rowClick="show">
            <TextField source="title" label="Title" />
            <TextField source="description" label="Description" />
        </Datagrid>
    </List>
);
