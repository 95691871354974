import React from 'react';
import {
    Edit,
    SimpleForm,
    TextField,
    PasswordInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    usePermissions,
} from 'react-admin';
import authProvider from '../../auth/authProvider';
import { makeStyles } from '@material-ui/core/styles';
import { Role } from '../../config/config';

export const AdminEdit = (props: any) => {
    const { permissions } = usePermissions();
    const currentId = authProvider.getCurrentId();

    const CustomToolbar = (props: any) => (
        <Toolbar {...props} classes={useStyles()}>
            {currentId === props.id || (permissions?.length && permissions[0] === Role.ROOT) ? (
                <SaveButton />
            ) : undefined}
            {currentId !== props.id && permissions?.length && permissions[0] === Role.ROOT && <DeleteButton />}
        </Toolbar>
    );

    const useStyles = makeStyles({
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    });

    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar {...props} />}>
                <TextField source="userId" label="User Id" />
                <TextField source="username" label="Username" />
                <TextField source="role" label="Role" />
                {currentId === props.id && (
                    <>
                        <div>
                            <PasswordInput source="oldPassword" label="Old Password" />
                        </div>
                        <div>
                            <PasswordInput source="newPassword" label="New Password" />
                        </div>
                    </>
                )}
                {currentId !== props.id && permissions?.length && permissions[0] === Role.ROOT && (
                    <div>
                        <PasswordInput source="newPassword" label="New Password" />
                    </div>
                )}
            </SimpleForm>
        </Edit>
    );
};
