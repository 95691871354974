import React from 'react';
import { Create, SimpleForm, TextInput, PasswordInput } from 'react-admin';

export const AdminCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="username" label="Username" />
            <PasswordInput source="password" label="Password" />
        </SimpleForm>
    </Create>
);
