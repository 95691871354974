import { ResourceDataProvider } from './ResourceDataProvider';
import { DataProviderParams } from './DataProvider';
import { fetchUtils } from 'react-admin';
import { ResourceName, API_URL } from '../config/config';
import { authCookie } from '../auth/authProvider';

const httpClient = fetchUtils.fetchJson;

export class BaseResourceDataProvider implements ResourceDataProvider {
    resourceName: ResourceName | string | null = null;

    getHttpClient(relativeUrl?: string, params?: any, baseUrl?: string, type?: string) {
        const url = (baseUrl || API_URL) + '/' + this.resourceName + '/' + (relativeUrl || '');
        const headers = this.getAuthorizationHeaders();

        if (params && params.headers && params.headers instanceof Headers) {
            for (const pair of params.headers.entries()) {
                headers.append(pair[0], pair[1]);
            }
        }

        return httpClient(url, { ...params, headers: headers });
    }

    getAuthorizationHeaders(): Headers {
        const token = authCookie.get();
        const headers = new Headers();
        headers.append('Authorization', `Bearer ${token}`);
        return headers;
    }

    getList(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }

    getOne(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }

    getMany(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }

    getManyReference(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }

    create(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }

    update(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }

    updateMany(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }

    delete(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }

    deleteMany(params: DataProviderParams): Promise<any> {
        return Promise.resolve();
    }
}
