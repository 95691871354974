import React from 'react';
import { List, Datagrid, DateField, ReferenceField, TextField, FunctionField, Filter, SelectInput } from 'react-admin';
import { ResourceName } from '../../config/config';
import { PageResults } from './../../pageResults/PageResults';

export const PayoutList = (props: any) => (
    <List
        {...props}
        filters={
            <Filter>
                <SelectInput
                    source="type"
                    choices={[
                        { id: 'payouts', name: 'PAYOUTS' },
                        { id: 'pendings', name: 'PENDINGS' },
                    ]}
                    alwaysOn
                    allowEmpty={false}
                    initialValue={'payouts'}
                />
            </Filter>
        }
        exporter={false}
        bulkActionButtons={false}
        hasCreate={true}
        pagination={<PageResults />}
        perPage={25}
        sort={{ field: 'createdDate', order: 'DESC' }}
    >
        <Datagrid>
            <ReferenceField label="Owner" source="ownerId" reference={ResourceName.users} link="show">
                <TextField source="ownerProfile.name" />
            </ReferenceField>
            <FunctionField
                label="Total Bookings completed"
                render={(record: any) => `${record.completedBookingCount >= 0 ? record.completedBookingCount : 'N/A'}`}
                sortable={false}
            />
            <TextField source="currency" sortable={false} />
            <FunctionField
                label="Gross"
                render={(record: any) => `${record.amountBeforeCommission / 100}`}
                options={{ maximumFractionDigits: 2 }}
                sortable={false}
            />
            <FunctionField
                label="commission"
                render={(record: any) => `${record.commission / 100}`}
                options={{ maximumFractionDigits: 2 }}
                sortable={false}
            />
            <FunctionField
                label="Total amount"
                render={(record: any) => `${record.amount / 100}`}
                options={{ maximumFractionDigits: 2 }}
                sortable={false}
            />
            <DateField source={'createdDate'} showTime={true} />
        </Datagrid>
    </List>
);
