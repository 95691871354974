import React from 'react';
import { SimpleForm, Edit, Toolbar, SaveButton, TextField, TextInput } from 'react-admin';

const UserEditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton label={'Send'} />
    </Toolbar>
);

export const ChatEdit = (props: any) => {
    return (
        <Edit successMessage="Message Sent" {...props}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <TextField source="id" label="Id" />
                <TextInput multiline source="message" label="message" />
            </SimpleForm>
        </Edit>
    );
};
