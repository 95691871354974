import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class ChatDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.chats;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params?.pagination || { page: 1, perPage: 10 };
        const { userId, email, name } = params.filter;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = 'userId';
        }
        const query = userId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  ids: userId,
                  email: email || undefined,
                  name: name || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  email: email || undefined,
                  name: name || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`users?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.users.map((user: any) => ({
                                ...user,
                                id: user.userId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`users/${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.userId } });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`chat-room/${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    message: params.data.message,
                }),
            })
                .then(() => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }
}

export default new ChatDataProvider();
