import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';

class PaymentDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.payments;
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json } });
                })
                .catch(reject);
        });
    }

    getMany(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.ids[0]}`)
                .then(({ json }: any) => {
                    resolve({
                        data: [{ ...json }],
                        total: 1,
                    });
                })
                .catch(reject);
        });
    }
}

export default new PaymentDataProvider();
