import React from 'react';
import { Show, TextField, SimpleShowLayout, DateField } from 'react-admin';
import { JsonField } from 'react-admin-json-view';

export const AppShow = (props: any) => (
    <Show {...props} hasEdit={true}>
        <SimpleShowLayout>
            <TextField source="settingsId" label="settings Id" />
            <TextField source="appId" label="app Id" />
            <TextField source="appName" />
            <DateField source="createdDate" />
            <JsonField
                source="theme"
                addLabel={true}
                jsonString={false}
                reactJsonOptions={{
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
            <JsonField
                source="meta"
                addLabel={true}
                jsonString={false}
                reactJsonOptions={{
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
            <JsonField
                source="assets"
                addLabel={true}
                jsonString={false}
                reactJsonOptions={{
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
        </SimpleShowLayout>
    </Show>
);
