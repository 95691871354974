import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class ReferralDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.referrals;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        let { field, order } = params.sort;

        const query = {
            skip: (page - 1) * perPage,
            take: perPage,
            sortKey: field || undefined,
            sortOrder: order || undefined,
        };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.referrals.map((referrals: any) => ({
                                ...referrals,
                                id: referrals.id,
                            })) || [],
                        total: json.referrals?.length || 0,
                    });
                })
                .catch(reject);
        });
    }
}

export default new ReferralDataProvider();
