import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    BooleanField,
    DateField,
    ChipField,
    Filter,
    SearchInput,
    SelectInput,
} from 'react-admin';
import { PageResults } from './../../pageResults/PageResults';

export const UserList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        hasCreate={true}
        perPage={25}
        pagination={<PageResults />}
        filters={
            <Filter>
                <SearchInput placeholder="User ID" source="userId" alwaysOn />
                <SearchInput placeholder="E-mail" source="email" alwaysOn />
                <SearchInput placeholder="Name" source="name" alwaysOn />
                <SelectInput
                    source="isOwner"
                    choices={[
                        { id: 'true', name: 'Owner' },
                        { id: 'false', name: 'Not an owner' },
                    ]}
                />
                <SelectInput
                    source="isExpert"
                    choices={[
                        { id: 'true', name: 'Expert' },
                        { id: 'false', name: 'Not an expert' },
                    ]}
                />
            </Filter>
        }
        sort={{ field: 'joinedDate', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" label="User ID" />
            <EmailField style={{ color: '#6482d6' }} source="email" />
            <BooleanField source="isVerified" label="Email Verified" sortable={false} />
            <BooleanField source="isOwner" label="Owner" sortable={false} />
            <BooleanField source="isExpert" label="Expert" sortable={false} />
            <TextField source="status" label="Status" sortable={false} />
            <TextField source="customerProfile.country" label="Country" sortable={false} />
            <ChipField source="authentication" label="Auth Type" sortable={false} />
            <DateField source="joinedDate" showTime={true} />
        </Datagrid>
    </List>
);
