import React from 'react';
import { Edit, SimpleForm, NumberInput, TextInput, SelectInput } from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

export const AppEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <NumberInput source="appId" />
            <TextInput source="appName" />
            <SelectInput
                source="className"
                choices={[
                    { id: 'PRIMARY', name: 'PRIMARY' },
                    { id: 'OPTIONAL', name: 'OPTIONAL' },
                ]}
            />
            <SelectInput
                source="platform"
                choices={[
                    { id: 'WEB', name: 'WEB' },
                    { id: 'MOBILE', name: 'MOBILE' },
                ]}
            />
            <JsonInput
                source="theme"
                validate={[]}
                jsonString={false}
                reactJsonOptions={{
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: true,
                }}
            />
            <JsonInput
                source="meta"
                validate={[]}
                jsonString={false}
                reactJsonOptions={{
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: true,
                }}
            />
            <JsonInput
                source="assets"
                validate={[]}
                jsonString={false}
                reactJsonOptions={{
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: true,
                }}
            />
        </SimpleForm>
    </Edit>
);
