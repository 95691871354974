import * as React from 'react';
import { Show, SimpleShowLayout, TextField, ArrayField, ReferenceField, Datagrid, DateField, NumberField, ChipField } from 'react-admin';
import { ResourceName } from '../../config/config';

export const CreditShow = (props: any) => (
    <Show {...props} hasEdit={false}>
        <SimpleShowLayout>
            <ArrayField source="customerProfile.bookings" label="Booking Credits for a Customer">
                <Datagrid>
                    <ReferenceField label="BookingId" source="bookingId" reference={ResourceName.bookings} link="show">
                        <TextField source="bookingId" />
                    </ReferenceField>
                    <TextField source="creditsSpent" label="Credits Spent" />
                </Datagrid>
            </ArrayField>
            <ArrayField source="customerProfile.creditHistory.logs" label="Customer Credit History">
                <Datagrid>
                    <TextField source="logId" label="Log Id" />
                    <NumberField source="credits" label="Credits" />
                    <ChipField source="creditStatus" />
                    <DateField source="createdDate" showTime={true} label="Logged Date" />
                </Datagrid>
            </ArrayField>

        </SimpleShowLayout>
    </Show>
);
