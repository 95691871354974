import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';
import authProvider from '../../auth/authProvider';

class AdminDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.admins;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params?.pagination || { page: 1, perPage: 10 };
        const { userId, username, role } = params.filter;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = 'userId';
        }
        const query = {
            skip: (page - 1) * perPage,
            take: perPage,
            userId: userId || undefined,
            username: username || undefined,
            role: role || undefined,
            sortKey: field || undefined,
            sortOrder: order || undefined,
        };
        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.admins.map((admin: any) => ({
                                ...admin,
                                id: admin.userId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        const currentId = authProvider.getCurrentId();
        if (params.id === currentId) {
            return new Promise((resolve, reject) => {
                this.getHttpClient(`me`)
                    .then(({ json }: any) => {
                        resolve({ data: { ...json, id: json.userId } });
                    })
                    .catch(reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.getHttpClient(`${params.id}`)
                    .then(({ json }: any) => {
                        resolve({ data: { ...json, id: json.userId } });
                    })
                    .catch(reject);
            });
        }
    }

    create(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient('', {
                method: 'POST',
                body: JSON.stringify(params.data),
            })
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.userId } });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        const currentId = authProvider.getCurrentId();
        if (params.id === currentId) {
            return new Promise((resolve, reject) => {
                this.getHttpClient(`me/password`, {
                    method: 'POST',
                    body: JSON.stringify({
                        oldPassword: params.data.oldPassword,
                        newPassword: params.data.newPassword,
                    }),
                })
                    .then((response: any) => {
                        resolve({ data: {} });
                    })
                    .catch(reject);
            });
        } else {
            return new Promise((resolve, reject) => {
                this.getHttpClient(`${params.id}/password`, {
                    method: 'POST',
                    body: JSON.stringify({
                        newPassword: params.data.newPassword,
                    }),
                })
                    .then((response: any) => {
                        resolve({ data: {} });
                    })
                    .catch(reject);
            });
        }
    }

    delete(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'DELETE',
                body: JSON.stringify({
                    userId: params.id,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }
}

export default new AdminDataProvider();
