import * as React from 'react';

const SignedURLsField = ({ source, record = {}, label }: { source: string; record: any; label: string }) => (
    <div>
        {record[source] &&
            record[source].map((url: string, index: number) => (
                <div>
                    <a href={url}>{`${label} (${index + 1})`}</a>
                    <small> (Avalilable for 5m)</small>
                </div>
            ))}
    </div>
);
SignedURLsField.defaultProps = {
    addLabel: true,
};
export default SignedURLsField;
