import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin';
import { ResourceName } from '../../config/config';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';

const serviceDurationValidation = (duration: number) => {
    if (!duration) {
        return 'The duration is required';
    }
    if (duration > 120) {
        return 'Maximum time range is 2 hours';
    }
    if (duration < 1) {
        return 'Minimum  time range is 1 min';
    }
    return undefined;
};
const required = (message = 'Required') => (value: number) => (value ? undefined : message);

const validateServiceDuration = [required(), serviceDurationValidation];

const ServiceTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const ServiceTooltipsComponent = (message: string) => {
    return (
        <div>
            <ServiceTooltip title={<React.Fragment>{message}</React.Fragment>}>
                <div>
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                </div>
            </ServiceTooltip>
        </div>
    );
};

export const ServiceCreate = (props: any) => {
    const useStyles = makeStyles((theme) => ({
        flex: {
            display: 'flex',
        },
    }));

    const classes = useStyles();

    return (
        <Create {...props}>
            <SimpleForm>
                <div className={classes.flex}>
                    <TextInput source="ownerUserId" label="Owner ID" />
                    {ServiceTooltipsComponent('Insert the owner ID')}
                </div>

                <div className={classes.flex}>
                    <TextInput source="title" label="Title" />
                    {ServiceTooltipsComponent('Insert the title of the service (character limit = 80)')}
                </div>

                <div className={classes.flex}>
                    <TextInput source="details" label="Details" />
                    {ServiceTooltipsComponent('Insert the service description of the service (character limit = 1000)')}
                </div>

                <div className={classes.flex}>
                    <SelectInput
                        source="currency"
                        choices={[
                            { id: 'EUR', name: '€' },
                            { id: 'LKR', name: 'Rs.' },
                            { id: 'USD', name: '$' },
                        ]}
                    />
                    {ServiceTooltipsComponent('select the currency')}
                </div>

                <div className={classes.flex}>
                    <NumberInput
                        source="durationMinutes"
                        label="Duration in minutes"
                        validate={validateServiceDuration}
                    />
                    {ServiceTooltipsComponent('The maximum duration is 120 minutes')}
                </div>

                <NumberInput
                    source="price"
                    format={(price: number) => price / 100}
                    parse={(price: number) => price * 100}
                    label="price"
                />

                <div className={classes.flex}>
                    <ReferenceInput
                        label="Category"
                        source="categoryKey"
                        reference={ResourceName.categories}
                        perPage={100}
                    >
                        <SelectInput optionText="displayName" optionValue="key" />
                    </ReferenceInput>
                    {ServiceTooltipsComponent(
                        'The category must me either primary or secondary categories of the above owner',
                    )}
                </div>

                <div className={classes.flex}>
                    <TextInput source="expertUserId" label="Expert User ID" />
                    {ServiceTooltipsComponent(
                        `${'If the particular user is an owner, insert owner ID or If the particular user is an expert, insert expert ID'}`,
                    )}
                </div>
            </SimpleForm>
        </Create>
    );
};
