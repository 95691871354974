import React from 'react';
import { Show, TextField, SimpleShowLayout, BooleanField, NumberField } from 'react-admin';

export const PaymentShow = (props: any) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <NumberField source="amount" />
            <NumberField source="amount_capturable" />
            <NumberField source="amount_received" />
            <TextField source="application" />
            <TextField source="application_fee_amount" />
            <TextField source="canceled_at" />
            <TextField source="cancellation_reason" />
            <TextField source="capture_method" />
            <TextField source="confirmation_method" />
            <NumberField source="created" />
            <TextField source="currency" />
            <TextField source="customer" />
            <TextField source="description" />
            <TextField source="invoice" />
            <TextField source="last_payment_error" />
            <BooleanField source="livemode" />
            <TextField source="metadata" />
            <TextField source="next_action" />
            <TextField source="on_behalf_of" />
            <TextField source="payment_method" />
            <TextField source="charges.data[0].payment_method_details.card.brand" label="Card Brand" />
            <TextField source="charges.data[0].payment_method_details.card.country" label="Card Country" />
            <TextField source="charges.data[0].payment_method_details.card.exp_month" label="Card Exp Month" />
            <TextField source="charges.data[0].payment_method_details.card.exp_year" label="Card Exp Year" />
            <TextField source="charges.data[0].payment_method_details.card.funding" label="Card Funding" />
            <TextField source="charges.data[0].payment_method_details.card.last4" label="Card Last 4 Digits" />
            <TextField source="receipt_email" />
            <TextField source="review" />
            <TextField source="setup_future_usage" />
            <TextField source="shipping" />
            <TextField source="source" />
            <TextField source="statement_descriptor" />
            <TextField source="statement_descriptor_suffix" />
            <TextField source="status" />
            <TextField source="transfer_data" />
            <TextField source="transfer_group" />
        </SimpleShowLayout>
    </Show>
);
