import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class PendingPayoutDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.payouts;
    }

    getOne(params: DataProviderParams): Promise<any> {
        const { startTimeMillis, endTimeMillis } = params.filter;
        const query = {
            startTimeMillis: startTimeMillis || undefined,
            endTimeMillis: endTimeMillis || undefined,
        };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}/pending/?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: `${params.id}-${Math.floor(Math.random() * 100)}` } });
                })
                .catch(reject);
        });
    }
}

export default new PendingPayoutDataProvider();
