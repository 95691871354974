import React from 'react';
import { Edit, SimpleForm, SelectInput, required } from 'react-admin';

export const ExpertApplicationsEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <SelectInput
                source="status"
                choices={[
                    { id: 'PENDING', name: 'PENDING' },
                    { id: 'REJECTED', name: 'REJECTED' },
                ]}
                validate={[required()]}
            />
        </SimpleForm>
    </Edit>
);
