import React from 'react';
import {
    Edit,
    SimpleForm,
    SelectInput,
    BooleanInput,
    Toolbar,
    ReferenceArrayInput,
    SelectArrayInput,
    SaveButton,
    ReferenceInput,
    TextInput,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import { makeStyles } from '@material-ui/core/styles';
import { useDelete } from 'react-admin';
import { useInput } from 'react-admin';

const useStyles = makeStyles(() => ({
    root: {
        marginLeft: 'auto',
    },
    block: {
        marginLeft: '20px',
    },
    deleteButton: {
        backgroundColor: '#E45B68',
        padding: '10px 32px',
        fontSize: '12px',
        borderRadius: 5,
        marginLeft: '20px',
    },
}));

const DeleteButton = (props: any) => {
    const classes = useStyles();
    const [deleteOne, { loading, error }] = useDelete(ResourceName.users, props.record.id, {
        username: props?.authData?.value?.username,
        password: props?.authData?.value?.password,
    });
    if (error) {
        return <p> Error: {error.message} </p>;
    }
    return (
        <button disabled={loading} onClick={deleteOne} color="red" className={classes.deleteButton}>
            {' '}
            Delete User
        </button>
    );
};

const UserEditToolbar = (props: any) => {
    const classes = useStyles();
    const { input } = useInput(props);
    return (
        <Toolbar {...props}>
            <SaveButton label="Update User" />
            <div className={classes.root}>
                <TextInput label="Username" source="username" onChange={input} />
                <TextInput
                    label="Password"
                    source="password"
                    type="password"
                    className={classes.block}
                    onChange={input}
                />
            </div>
            <DeleteButton authData={input} />
        </Toolbar>
    );
};

export const UserEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm toolbar={<UserEditToolbar />}>
            <BooleanInput source="isOwner" label="Owner" />
            <BooleanInput source="isExpert" label="Expert" />
            <ReferenceInput
                label="Category"
                source="ownerProfile.category.key"
                reference={ResourceName.categories}
                perPage={100}
            >
                <SelectInput optionText="displayName" optionValue="key" />
            </ReferenceInput>
            <SelectInput
                source="ownerProfile.type"
                choices={[
                    { id: 'INDIVIDUAL', name: 'INDIVIDUAL' },
                    { id: 'BUSINESS', name: 'BUSINESS' },
                ]}
            />

            <ReferenceArrayInput
                label="Secondary Categories"
                source="secondaryCategoryKeys"
                reference={ResourceName.categories}
                perPage={100}
            >
                <SelectArrayInput optionText="displayName" optionValue="key" />
            </ReferenceArrayInput>

            <SelectInput
                source="status"
                choices={[
                    { id: 'ACTIVATED', name: 'ACTIVATED' },
                    { id: 'DEACTIVATED', name: 'DEACTIVATED' },
                ]}
            />
        </SimpleForm>
    </Edit>
);
