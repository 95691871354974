import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    ImageInput,
    ImageField,
    Toolbar,
    useInput,
    useDelete,
    SaveButton,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    deleteButton: {
        color: 'red',
        marginLeft: 'auto',
    },
}));

const DeleteButton = (props: any) => {
    const classes = useStyles();
    const [deleteOne, { loading, error }] = useDelete(ResourceName.categories, props.record.id);
    if (error) {
        return <p> Error: {error.message} </p>;
    }
    return (
        <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            disabled={loading}
            onClick={deleteOne}
            className={classes.deleteButton}
        >
            Delete Category
        </Button>
    );
};

const CategoryEditToolbar = (props: any) => {
    const { input } = useInput(props);
    return (
        <Toolbar {...props}>
            <SaveButton label="Update Category" />
            <DeleteButton authData={input} />
        </Toolbar>
    );
};

export const CategoryEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm toolbar={<CategoryEditToolbar />}>
            <TextInput source="displayName" label="Display Name" validate={[required()]} />
            <TextInput
                multiline
                resettable
                source="meta"
                label="Meta Data (JSON)"
                format={(meta: any) => {
                    if (typeof meta === 'object') {
                        if (meta === null) {
                            return '{}';
                        }
                        try {
                            return JSON.stringify(meta);
                        } catch (error) {
                            return '{}';
                        }
                    }
                    return meta;
                }}
                validate={[required()]}
            />
            <ImageInput source="image" label="Category image" accept="image/*">
                <ImageField source="image" title="categoryImage" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);
