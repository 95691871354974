import {
    BooleanInput,
    Edit,
    NumberInput,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    ImageInput,
    ImageField,
    useDataProvider,
    FunctionField,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import React, { useEffect, useState } from 'react';
import { ResourceName } from '../../config/config';
import S3ImageField from '../../field-components/S3ImageField';

const ServiceEditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const renderOption = (choice: any) => `${choice.name}`;

export const ServiceEdit = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(true);
    const dataProvider = useDataProvider();
    const [categories, setCategories] = useState([]);

    const getOwnerSecondaryCategoriesByServiceId = (serviceId: string) => {
        dataProvider
            .getOne(ResourceName.services, {
                id: serviceId,
            })
            .then(({ data }: any) => {
                if (data?.owner) {
                    const secondaryCategories = data?.owner?.secondaryCategories?.map((category: any, i: any) => {
                        return { id: category.key, name: category.displayName };
                    });

                    const category = data?.owner?.category;

                    const categoryObject = { id: category?.key, name: category?.displayName };

                    const categoryList = secondaryCategories;

                    categoryList.push(categoryObject);

                    setCategories(categoryList);
                }
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    };

    useEffect(() => {
        getOwnerSecondaryCategoriesByServiceId(props.id);
    }, [getOwnerSecondaryCategoriesByServiceId, props.id]);

    return (
        <Edit {...props}>
            <SimpleForm toolbar={<ServiceEditToolbar />}>
                <BooleanInput source="featured" label="Featured" />
                <NumberInput source="featuredRank" label="Featured Rank" />

                <SelectInput
                    source="status"
                    choices={[
                        { id: 'ACTIVE', name: 'ACTIVE' },
                        { id: 'INACTIVE', name: 'INACTIVE' },
                        { id: 'PENDING_APPROVAL', name: 'PENDING_APPROVAL' },
                    ]}
                />
                <SelectInput label="category" source="category.key" choices={categories} optionText={renderOption} />
                <TextInput multiline source="details" label="Details" />
                <S3ImageField source="image" label="Current image" />
                <ImageInput source="image" label="Service image" accept="image/jpeg">
                    <ImageField source="image" title="serviceImage" />
                </ImageInput>

                {/* EXPUB-2159 Disabled
                <ArrayInput source="packs" formClassName="pack-status-container">
                    <SimpleFormIterator disableRemove disableAdd>
                        <FunctionField label={false} render={(record: any) => `Pack of ${record.numberOfSessions}`} />
                        <SelectInput
                            label="status"
                            source="status"
                            choices={[
                                { id: 'ACTIVE', name: 'ACTIVE' },
                                { id: 'INACTIVE', name: 'INACTIVE' },
                            ]}
                        />
                    </SimpleFormIterator>
                </ArrayInput> */}
            </SimpleForm>
        </Edit>
    );
};
