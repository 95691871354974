import React from 'react';
import { List, Datagrid, DateField, FunctionField } from 'react-admin';
import { PageResults } from './../../pageResults/PageResults';

export const ExchangeRateList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        hasCreate={false}
        pagination={<PageResults />}
        perPage={25}
    >
        <Datagrid>
            <FunctionField
                label="Rate"
                render={(record: any) => `1 ${record?.currency?.code} = ${record?.rate} ${record?.toCurrency?.code}`}
                sortable={false}
            />
            <DateField source="createdDate" label="Updated Date" showTime={true} sortable={false} />
        </Datagrid>
    </List>
);
