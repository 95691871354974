import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    SelectInput,
    FunctionField,
    ReferenceField,
    DateField,
    ChipField,
    Filter,
    SearchInput,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import { PageResults } from './../../pageResults/PageResults';

export const BookingList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        pagination={<PageResults />}
        perPage={25}
        filters={
            <Filter>
                <SearchInput placeholder="Booking ID" source="bookingId" alwaysOn />
                <SearchInput placeholder="Service ID" source="serviceId" alwaysOn />
                <SearchInput placeholder="Group Booking ID" source="groupBookingId" alwaysOn />
                <SearchInput placeholder="Customer ID" source="customerId" alwaysOn />
                <SelectInput
                    source="status"
                    choices={[
                        { id: 'PENDING_ACCEPTANCE', name: 'PENDING_ACCEPTANCE' },
                        { id: 'CHARGE_FAILED', name: 'CHARGE_FAILED' },
                        { id: 'REJECTED', name: 'REJECTED' },
                        { id: 'ACCEPTED', name: 'ACCEPTED' },
                        { id: 'VOIDED', name: 'VOIDED' },
                        { id: 'COMPLETED', name: 'COMPLETED' },
                    ]}
                    alwaysOn
                />
                <SelectInput
                    source="type"
                    choices={[
                        { id: 'SCHEDULED_BOOKING', name: 'SCHEDULED_BOOKING' },
                        { id: 'INSTANT_BOOKING', name: 'INSTANT_BOOKING' },
                        { id: 'GROUP_BOOKING', name: 'GROUP_BOOKING' },
                    ]}
                    alwaysOn
                />
            </Filter>
        }
        sort={{ field: 'createdDate', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="bookingId" />
            <ReferenceField label="Service" source="service.serviceId" reference={ResourceName.services} link="show">
                <TextField source="title" />
            </ReferenceField>
            <ReferenceField label="Customer" source="customer.userId" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <ReferenceField label="Expert" source="expert.userId" reference={ResourceName.users} link="show">
                <TextField source="expertProfile.name" />
            </ReferenceField>
            <ReferenceField label="Owner" source="service.ownerId" reference={ResourceName.users} link="show">
                <TextField source="ownerProfile.name" />
            </ReferenceField>
            <ChipField source="type" />
            <ChipField source="status" />
            <FunctionField
                label="Schedule Date"
                render={(record: any) => {
                    if (!record || !record?.scheduledTime) {
                        return '';
                    }
                    try {
                        if (record?.type === 'SCHEDULED_BOOKING' || record?.type === 'GROUP_BOOKING') {
                            const scheduleDate = record?.scheduledTime;
                            return `${scheduleDate.slice(0, 10)}, ${scheduleDate.slice(11, 19)}`;
                        } else {
                            return 'INSTANT BOOKING';
                        }
                    } catch (error) {}
                }}
            />
            <FunctionField
                label="Group Booking id"
                render={(record: any) => {
                    if (!record) {
                        return '';
                    }
                    try {
                        if (record?.type === 'GROUP_BOOKING') {
                            return record?.groupBookingId;
                        } else {
                            return 'NOT A GROUP BOOKING';
                        }
                    } catch (error) {}
                }}
            />

            <DateField label="Created Date" source="createdDate" showTime={true} />
        </Datagrid>
    </List>
);
