import { ResourceName } from '../config/config';
import { DataProvider, DataProviderParams } from './DataProvider';
import { ResourceDataProvider } from './ResourceDataProvider';
import serviceDataProvider from './services/serviceDataProvider';
import userDataProvider from './users/userDataProvider';
import bookingDataProvider from './bookings/bookingDataProvider';
import categoryDataProvider from './categories/categoryDataProvider';
import currencyDataProvider from './currencies/currencyDataProvider';
import exchangeRatesDataProvider from './exchange-rates/exchangeRatesDataProvider';
import payoutDataProvider from './payouts/payoutDataProvider';
import pendingPayoutDataProvider from './pending-payouts/pendingPayoutDataProvider';
import paymentDataProvider from './payments/paymentDataProvider';
import callLogDataProvider from './call-logs/callLogDataProvider';
import deviceDataProvider from './devices/deviceDataProvider';
import forgotDataProvider from './forgot/forgotDataProvider';
import rejectServicesDataProvider from './reject-services/rejectServicesDataProvider';
import referralDataProvider from './referral-download/referralDownloadDataProvider';
import expertApplicationDataProvider from './expert-applications/expertApplicationDataProvider';
import notificationDataProvider from './notifications/notificationDataProvider';
import dashboardDataProvider from './dashboards/dashboardDataProvider';
import chatDataProvider from './chats/chatDataProvider';
import creditDataProvider from './credits/creditDataProvider';
import expertsDataProvider from './experts/expertsDataProvider';
import adminDataProvider from './admins/adminDataProvider';
import appConfigsDataProvider from './app-configs/appConfigsDataProvider';
import appsDataProvider from './apps/appsDataProvider';

const dataProviderStrategy: { [resourceName: string]: any } = {
    [ResourceName.services]: serviceDataProvider,
    [ResourceName.users]: userDataProvider,
    [ResourceName.admins]: adminDataProvider,
    [ResourceName.bookings]: bookingDataProvider,
    [ResourceName.categories]: categoryDataProvider,
    [ResourceName.currencies]: currencyDataProvider,
    [ResourceName.exchangeRates]: exchangeRatesDataProvider,
    [ResourceName.payouts]: payoutDataProvider,
    [ResourceName.payments]: paymentDataProvider,
    [ResourceName.forgot]: forgotDataProvider,
    [ResourceName.pendingPayouts]: pendingPayoutDataProvider,
    [ResourceName.callLogs]: callLogDataProvider,
    [ResourceName.devices]: deviceDataProvider,
    [ResourceName.rejectServices]: rejectServicesDataProvider,
    [ResourceName.referrals]: referralDataProvider,
    [ResourceName.expertApplications]: expertApplicationDataProvider,
    [ResourceName.notifications]: notificationDataProvider,
    [ResourceName.dashboards]: dashboardDataProvider,
    [ResourceName.chats]: chatDataProvider,
    [ResourceName.credits]: creditDataProvider,
    [ResourceName.experts]: expertsDataProvider,
    [ResourceName.appConfigs]: appConfigsDataProvider,
    [ResourceName.apps]: appsDataProvider,
};

const getResourceDataProvider = (resource: string): ResourceDataProvider | null => {
    return dataProviderStrategy[resource] || null;
};

class BaseDataProvider implements DataProvider {
    getList(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.getList(params);
        }
        return Promise.resolve();
    }

    getOne(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.getOne(params);
        }
        return Promise.resolve();
    }

    getMany(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.getMany(params);
        }
        return Promise.resolve();
    }

    getManyReference(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.getManyReference(params);
        }
        return Promise.resolve();
    }

    create(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.create(params);
        }
        return Promise.resolve();
    }

    update(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.update(params);
        }
        return Promise.resolve();
    }

    updateMany(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.updateMany(params);
        }
        return Promise.resolve();
    }

    delete(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.delete(params);
        }
        return Promise.resolve();
    }

    deleteMany(resource: string, params: DataProviderParams): Promise<any> {
        const resourceDataProvider = getResourceDataProvider(resource);
        if (resourceDataProvider) {
            return resourceDataProvider.deleteMany(params);
        }
        return Promise.resolve();
    }
}

export default new BaseDataProvider();
