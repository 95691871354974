import React from 'react';
import { List, Datagrid, TextField, ChipField, DateField } from 'react-admin';
import { PageResults } from '../../pageResults/PageResults';

export const ReferralList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        pagination={<PageResults />}
        perPage={25}
        sort={{ field: 'createdDate', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="referralCode" />
            <ChipField source="type" sortable={false} />
            <ChipField source="status" sortable={false} />
            <DateField source="createdDate" showTime={true} />
        </Datagrid>
    </List>
);
