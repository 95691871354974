import React from 'react';
import {
    TextField,
    SimpleShowLayout,
    NumberField,
    ReferenceField,
    DateField,
    FunctionField,
    ShowController,
    ShowView,
} from 'react-admin';
import { ResourceName } from '../../config/config';

export const BookingShow = (props: any) => (
    <ShowController {...props}>
        {(controllerProps: any) => (
            <ShowView {...props} {...controllerProps} hasEdit={true}>
                <SimpleShowLayout>
                    <TextField source="bookingId" />
                    <ReferenceField
                        label="Service"
                        source="service.serviceId"
                        reference={ResourceName.services}
                        link="show"
                    >
                        <TextField source="title" />
                    </ReferenceField>
                    <ReferenceField
                        label="Customer"
                        source="customer.userId"
                        reference={ResourceName.users}
                        link="show"
                    >
                        <TextField source="customerProfile.name" />
                    </ReferenceField>
                    <ReferenceField label="Expert" source="expert.userId" reference={ResourceName.users} link="show">
                        <TextField source="expertProfile.name" />
                    </ReferenceField>
                    <ReferenceField label="Owner" source="service.ownerId" reference={ResourceName.users} link="show">
                        <TextField source="ownerProfile.name" />
                    </ReferenceField>
                    <TextField source="type" />
                    <TextField source="status" />
                    <TextField source="rejectedReason" />
                    {controllerProps.record && controllerProps.record.type === 'SCHEDULED_BOOKING' && (
                        <DateField source="scheduledTime" showTime={true} />
                    )}
                    <NumberField source="durationMinutes" label="Duration (Minutes)" />
                    <TextField source="currency" />
                    <FunctionField
                        label="Price"
                        render={(record: any) => `${record.price / 100}`}
                        options={{ maximumFractionDigits: 2 }}
                    />
                    <FunctionField
                        label="Group Booking id"
                        render={(record: any) => {
                            if (!record) {
                                return '';
                            }
                            try {
                                if (record?.type === 'GROUP_BOOKING') {
                                    return record?.groupBookingId;
                                } else {
                                    return 'NOT A GROUP BOOKING';
                                }
                            } catch (error) {}
                        }}
                    />
                    <FunctionField
                        label="service slots"
                        render={(record: any) => {
                            if (!record) {
                                return '';
                            }
                            try {
                                if (record?.type === 'GROUP_BOOKING') {
                                    return 'Total Slots: ' + record?.service?.recurrence?.allowedParticipants;
                                } else {
                                    return 'NOT A GROUP BOOKING';
                                }
                            } catch (error) {}
                        }}
                    />
                    <FunctionField
                        label="Booked slots"
                        render={(record: any) => {
                            if (!record) {
                                return '';
                            }
                            try {
                                if (record?.type === 'GROUP_BOOKING') {
                                    const bookedSlots = record?.service?.bookings.map(
                                        (groupBooking: any) => groupBooking?.groupBookingId === record.groupBookingId,
                                    );
                                    const remainingSlots =
                                        record?.service?.recurrence?.allowedParticipants - bookedSlots?.length;
                                    return (
                                        'Booked Slots: ' +
                                        bookedSlots?.length +
                                        '   ,   Remaining Slots: ' +
                                        remainingSlots
                                    );
                                } else {
                                    return 'NOT A GROUP BOOKING';
                                }
                            } catch (error) {}
                        }}
                    />

                    <NumberField source="rating.rating" label="Rating" />
                    <TextField source="notes" />
                    <ReferenceField
                        label="Stripe Payment Reference"
                        source="paymentRef"
                        reference={ResourceName.payments}
                        link="show"
                    >
                        <TextField source="id" />
                    </ReferenceField>
                    <DateField label="Created Date" source="createdDate" showTime={true} />
                </SimpleShowLayout>
            </ShowView>
        )}
    </ShowController>
);
