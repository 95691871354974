import React, { useEffect, useState } from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout,
    BooleanField,
    NumberField,
    ReferenceField,
    FunctionField,
    useDataProvider,
    Datagrid,
    ArrayField,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import AvailabilityField from '../../field-components/availabilityField';
import S3ImageField from '../../field-components/S3ImageField';

const getStartMinAndHour = (startTimeInMin: number, startTimeInHours: number) => {
    if (startTimeInMin > 0 && startTimeInMin < 60) {
        if (startTimeInHours < 10) {
            return `Start at:  0${startTimeInHours} : ${startTimeInMin} h`;
        }
        return `Start: ${startTimeInHours} : ${startTimeInMin} h`;
    } else if (!startTimeInMin) {
        return `Start: ${startTimeInHours}h`;
    }
};

export const ServiceShow = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(true);
    const dataProvider = useDataProvider();
    const [serviceCallType, setServiceCallType] = useState({});

    const getServiceByServiceId = (serviceId: string) => {
        dataProvider
            .getOne(ResourceName.services, {
                id: serviceId,
            })
            .then(({ data }: any) => {
                if (data) {
                    const serviceCallType = data.serviceCallType;
                    setServiceCallType(serviceCallType);
                }
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    };

    useEffect(() => {
        getServiceByServiceId(props.id);
    }, [getServiceByServiceId, props.id]);

    return (
        <Show {...props} hasEdit={true}>
            <SimpleShowLayout>
                <TextField source="serviceId" label="Service ID" />
                <TextField source="title" label="Title" />
                <TextField source="serviceName" label="Service Name" />
                <ReferenceField label="Owner" source="owner.userId" reference={ResourceName.users} link="show">
                    <TextField source="ownerProfile.name" />
                </ReferenceField>
                <ReferenceField label="Expert" source="expert.userId" reference={ResourceName.users} link="show">
                    <TextField source="expertProfile.name" />
                </ReferenceField>
                <ReferenceField label="Category" source="category.key" reference={ResourceName.categories} link="show">
                    <TextField source="displayName" />
                </ReferenceField>
                <S3ImageField source="image" label="Service Image" />
                <TextField source="status" label="Status" />
                <ReferenceField
                    label="Reject Service"
                    source="serviceId"
                    reference={ResourceName.rejectServices}
                    link="edit"
                >
                    <FunctionField render={(record: any) => 'Click here to send reject email'} />
                </ReferenceField>
                <TextField multiline source="details" label="Details" />
                <TextField source="currency" label="Currency" />
                <FunctionField
                    label="Price"
                    render={(record: any) => `${record.price / 100}`}
                    options={{ maximumFractionDigits: 2 }}
                />
                <NumberField source="rating" label="Rating" />
                <NumberField source="ratingCount" label="Rating Count" />
                <BooleanField source="featured" label="Featured" />
                <NumberField source="featuredRank" label="Featured Rank " />
                <AvailabilityField source="expert" record />
                <TextField source="serviceCallType" label="service Call Type" />

                <FunctionField
                    label="Group-service time"
                    render={(record: any) => {
                        if (serviceCallType === 'ONE_TO_ONE_CALL') {
                            return 'This is not a group service';
                        }
                        try {
                            const startTimeInHours = Math.floor(
                                record?.recurrence?.serviceTime?.serviceStartTimeInMin / 60,
                            );
                            const startTimeInMin = record?.recurrence?.serviceTime?.serviceStartTimeInMin % 60;

                            return getStartMinAndHour(startTimeInMin, startTimeInHours);
                        } catch (error) { }
                        return '';
                    }}
                />
                <FunctionField
                    label="Group-service start date"
                    render={(record: any) => {
                        if (serviceCallType === 'ONE_TO_ONE_CALL') {
                            return 'This is not a group service';
                        }
                        try {
                            return record.recurrence?.serviceTime?.serviceStartDate;
                        } catch (error) { }
                        return '';
                    }}
                />

                <FunctionField
                    label="Group-service duration in minutes"
                    render={(record: any) => {
                        if (serviceCallType === 'ONE_TO_ONE_CALL') {
                            return 'This is not a group service';
                        }
                        try {
                            return record.recurrence?.serviceTime?.durationInMin + '  min';
                        } catch (error) { }
                        return '';
                    }}
                />
                <FunctionField
                    label="Group-service recurrence pattern"
                    render={(record: any) => {
                        if (serviceCallType === 'ONE_TO_ONE_CALL') {
                            return 'This is not a group service';
                        }
                        try {
                            return JSON.stringify(record.recurrence?.recurrencePattern);
                        } catch (error) { }
                        return '';
                    }}
                />
                <FunctionField
                    label="Group-service end pattern"
                    render={(record: any) => {
                        if (serviceCallType === 'ONE_TO_ONE_CALL') {
                            return 'This is not a group service';
                        }
                        try {
                            if (
                                record.recurrence?.recurrentEndType?.noEnd !== null &&
                                record.recurrence?.recurrentEndType?.noEnd !== undefined
                            ) {
                                return 'no End :  ' + JSON.stringify(record.recurrence?.recurrentEndType?.noEnd);
                            } else if (
                                record.recurrence?.recurrentEndType?.endAtTime !== null &&
                                record.recurrence?.recurrentEndType?.endAtTime !== undefined
                            ) {
                                return (
                                    'end At Time : ' + JSON.stringify(record.recurrence?.recurrentEndType?.endAtTime)
                                );
                            } else if (
                                record.recurrence?.recurrentEndType?.endAfterNumOfOccurrences !== null &&
                                record.recurrence?.recurrentEndType?.endAfterNumOfOccurrences !== undefined
                            ) {
                                return (
                                    'end after occurrences : ' +
                                    JSON.stringify(record.recurrence?.recurrentEndType?.endAfterNumOfOccurrences)
                                );
                            }
                        } catch (error) { }
                        return '';
                    }}
                />
                <FunctionField
                    label="Group-service allowed participants"
                    render={(record: any) => {
                        if (serviceCallType === 'ONE_TO_ONE_CALL') {
                            return 'This is not a group service';
                        }
                        try {
                            return JSON.stringify(record.recurrence?.allowedParticipants);
                        } catch (error) { }
                        return '';
                    }}
                />
                <FunctionField
                    multiline
                    label="Scheduled time slots"
                    render={(record: any) => {
                        if (!record || !record.bookings) {
                            return '';
                        }
                        try {
                            return (
                                <>
                                    {record.bookings.map((booking: any) => (
                                        <div key={booking.bookingId}>
                                            {JSON.stringify(new Date(booking.scheduledTime).toLocaleString())}
                                        </div>
                                    ))}
                                </>
                            );
                        } catch (error) { }
                        return '';
                    }}
                />
                {/* EXPUB-2158 Disabled
                <ArrayField source="packs">
                    <Datagrid>
                        <FunctionField label="Title" render={(record: any) => `Pack of ${record.numberOfSessions}`} />
                        <TextField label="Status" source="status" sortable={false} />
                        <TextField label="Number of sessions" source="numberOfSessions" sortable={false} />
                        <TextField label="Interval" source="interval" sortable={false} />
                        <FunctionField
                            label="Discounted Price per session"
                            render={(record: any) => `${record.discountedPricePerSession / 100}`}
                            options={{ maximumFractionDigits: 2 }}
                        />
                        <TextField label="Validity Period" source="validPeriod" sortable={false} />
                    </Datagrid>
                </ArrayField> */}
            </SimpleShowLayout>
        </Show>
    );
};
