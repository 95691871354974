import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';

class dashboardDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.dashboards;
    }

    getList(params: DataProviderParams): Promise<any> {
        return Promise.resolve({
            data: [{ id: 'overview', title: 'Overview Dashboard', description: 'This is the overview dashboard' }],
            total: 1,
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({
                        data: {
                            ...json,
                            id: params.id,
                        },
                    });
                })
                .catch(reject);
        });
    }
}

export default new dashboardDataProvider();
