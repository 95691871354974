import React from 'react';
import { List, Datagrid, TextField, ReferenceField, DateField, Filter, SearchInput } from 'react-admin';
import { ResourceName } from '../../config/config';
import { PageResults } from './../../pageResults/PageResults';

export const DeviceList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        perPage={25}
        pagination={<PageResults />}
        filters={
            <Filter>
                <SearchInput placeholder="User ID" source="userId" alwaysOn />
            </Filter>
        }
        sort={{ field: 'createdDate', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <ReferenceField label="User" source="userId" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <TextField source="deviceUniqId" label="Device Unique Id" sortable={false} />
            <TextField source="deviceInfo" label="Device Info" sortable={false} />
            <DateField source="createdDate" showTime={true} />
        </Datagrid>
    </List>
);
