import React from 'react';
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin';
import { ResourceName } from '../../config/config';

const redirect = () => `/${ResourceName.users}`;

const ForgotEditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton label="SEND EMAIL" />
    </Toolbar>
);

export const ForgotEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm toolbar={<ForgotEditToolbar />} redirect={redirect}>
            <TextInput type="email" source="email" label="Email" />
        </SimpleForm>
    </Edit>
);
