import React from 'react';
import { Show, TextField, SimpleShowLayout, ReferenceField, DateField, ChipField, NumberField } from 'react-admin';
import { ResourceName } from '../../config/config';

export const NotificationShow = (props: any) => (
    <Show {...props} hasEdit={false}>
        <SimpleShowLayout>
            <TextField source="id" label="ID" />
            <ChipField source="status" label="Status" />
            <ReferenceField label="User" source="user.userId" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <TextField source="payload" label="Payload" multiline />
            <NumberField source="ttlMs" label="TTL(ms)" />
            <DateField source="createdDate" showTime={true} />
        </SimpleShowLayout>
    </Show>
);
