import React from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout,
    NumberField,
    ReferenceField,
    ChipField,
    BooleanField,
    FunctionField,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import SignedURLsField from '../../field-components/SignedURLsField';

export const ExpertApplicationsShow = (props: any) => (
    <Show {...props} hasEdit={true}>
        <SimpleShowLayout>
            <TextField source="applicationId" label="Application ID" />
            <ChipField source="type" label="Type" />
            <ChipField source="status" label="Status" />
            <ReferenceField label="User" source="user.userId" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <TextField multiline source="description" label="Description" />
            <TextField source="phoneNumber" label="Phone Number" />
            <TextField source="addressLine1" label="addressLine1" />
            <TextField source="addressLine2" label="addressLine2" />
            <TextField source="city" label="City" />
            <TextField source="stateProvinceRegion" label="State/Province/Region" />
            <TextField source="postalCode" label="Postal Code" />
            <NumberField source="yearsOfExperience" label="Experience (Years)" />
            <BooleanField source="hasConductedOnlineSessions" label="Has Conducted Online Sessions" />
            <ReferenceField label="Category" source="category.key" reference={ResourceName.categories} link="show">
                <TextField source="displayName" />
            </ReferenceField>
            <TextField source="categoryDescription" label="Category Description" />
            <FunctionField
                label="Secondary Categories"
                render={(record: any) => {
                    if (!record || !record?.secondaryCategories?.length) {
                        return '';
                    }
                    try {
                        return JSON.stringify(
                            record?.secondaryCategories?.map((category: any) => category?.displayName),
                        );
                    } catch (error) {}
                }}
            />
            <FunctionField
                multiline
                label="Languages"
                render={(record: any) => {
                    if (!record || !record.languages) {
                        return '';
                    }
                    try {
                        return JSON.stringify(record.languages, undefined, 2);
                    } catch (error) {}
                    return '';
                }}
            />
            <FunctionField
                multiline
                label="Availability"
                render={(record: any) => {
                    if (!record || !record.expertAvailability) {
                        return '';
                    }
                    const convertIntervalsToString = (intervals: { start: number; end: number }[]) => {
                        return intervals
                            .map((interval) => {
                                const startHour = Math.floor(interval.start / 60);
                                const startMin = Math.floor(interval.start % 60);
                                const endHour = Math.floor(interval.end / 60);
                                const endMin = Math.floor(interval.end % 60);
                                return `${startHour}:${startMin < 10 ? '0' + startMin : startMin} to ${endHour}:${
                                    endMin < 10 ? '0' + endMin : endMin
                                }`;
                            })
                            .join(', ');
                    };

                    try {
                        const timezone = record.expertAvailability?.timezone || '';
                        const monday = convertIntervalsToString(record.expertAvailability?.monday || []);
                        const tuesday = convertIntervalsToString(record.expertAvailability?.tuesday || []);
                        const wednesday = convertIntervalsToString(record.expertAvailability?.wednesday || []);
                        const thursday = convertIntervalsToString(record.expertAvailability?.thursday || []);
                        const friday = convertIntervalsToString(record.expertAvailability?.friday || []);
                        const saturday = convertIntervalsToString(record.expertAvailability?.saturday || []);
                        const sunday = convertIntervalsToString(record.expertAvailability?.sunday || []);

                        return `Timezone: ${timezone},\n [ Monday: ${monday || '-'} ], \n\
                            [ Tuesday: ${tuesday || '-'} ], \n\
                            [ Wednesday: ${wednesday || '-'} ], \n\
                            [ Thursday: ${thursday || '-'} ], \n\
                            [ Friday: ${friday || '-'} ], \n\
                            [ Saturday: ${saturday || '-'} ], \n\
                            [ Sunday: ${sunday || '-'} ]`;
                    } catch (error) {}
                    return '';
                }}
            />
            <FunctionField
                multiline
                label="Social Media"
                render={(record: any) => {
                    if (!record || !record.socialMediaUrls) {
                        return '';
                    }
                    try {
                        return (record.socialMediaUrls || [])
                            .map((urlInfo: { url: string; type: string }) => urlInfo.url)
                            .join(', ');
                    } catch (error) {}
                    return '';
                }}
            />
            <TextField source="idDocumentType" label="ID Document Type" />

            <SignedURLsField source="personalFilesUrls" label="Personal Files" record />
            <SignedURLsField source="professionalFilesUrls" label="Professional Files" record />
            <SignedURLsField source="marketingFlyersUrls" label="Marketing Flyers" record />
        </SimpleShowLayout>
    </Show>
);
