import React from 'react';
import { Create, SimpleForm, TextInput, required, NumberInput, FormDataConsumer, SelectInput } from 'react-admin';

export const NotificationCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="userId" label="User ID" validate={[required()]} />
            <SelectInput
                source="preset"
                choices={[
                    { id: 'CUSTOM', name: 'CUSTOM' },
                    { id: 'ACCOUNT_ACTIVATED', name: 'ACCOUNT_ACTIVATED' },
                    { id: 'CREATE_YOUR_FIRST_SERVICE', name: 'CREATE_YOUR_FIRST_SERVICE' },
                    { id: 'TEST_CALL', name: 'TEST_CALL' },
                ]}
                alwaysOn
                validate={[required()]}
            />
            <FormDataConsumer>
                {({ formData }: any) =>
                    formData?.preset === 'CUSTOM' ? (
                        <TextInput source="notificationTitle" label="Title" validate={[required()]} />
                    ) : null
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }: any) =>
                    formData?.preset === 'CUSTOM' ? (
                        <TextInput source="notificationBody" label="Body" validate={[required()]} />
                    ) : null
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }: any) =>
                    formData?.preset === 'CUSTOM' ? (
                        <TextInput
                            multiline
                            resettable
                            source="data"
                            label="Data (JSON)"
                            defaultValue="{}"
                            validate={[required()]}
                        />
                    ) : null
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }: any) =>
                    formData?.preset === 'CUSTOM' ? (
                        <NumberInput source="ttl" label="TTL(ms)" defaultValue={172800000} validate={[required()]} />
                    ) : null
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }: any) =>
                    formData?.preset === 'CUSTOM' ? <TextInput source="sound" label="Sound" /> : null
                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
