import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ChipField,
    ReferenceField,
    DateField,
    Filter,
    SearchInput,
    SelectInput,
} from 'react-admin';
import { PageResults } from '../../pageResults/PageResults';
import { ResourceName } from '../../config/config';

export const ExpertApplicationsList = (props: any) => (
    <List
        exporter={false}
        bulkActionButtons={false}
        pagination={<PageResults />}
        perPage={25}
        filters={
            <Filter>
                <SearchInput placeholder="Application ID" source="applicationId" alwaysOn />
                <SelectInput
                    source="status"
                    choices={[
                        { id: 'PENDING', name: 'PENDING' },
                        { id: 'APPROVED', name: 'APPROVED' },
                        { id: 'REJECTED', name: 'REJECTED' },
                        { id: 'ALL', name: 'ALL' },
                    ]}
                    alwaysOn
                />
            </Filter>
        }
        sort={{ field: 'createdDate', order: 'DESC' }}
        {...props}
    >
        <Datagrid rowClick="show">
            <TextField source="applicationId" label={'Application ID'} />
            <ChipField source="type" sortable={false} />
            <ReferenceField label="User" source="user.userId" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <ReferenceField label="Category" source="category.key" reference={ResourceName.categories} link="show">
                <TextField source="displayName" />
            </ReferenceField>
            <ChipField source="status" sortable={false} />
            <DateField source="createdDate" label={'Created Date'} showTime={true} />
        </Datagrid>
    </List>
);
