import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class BookingDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.bookings;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { bookingId, status, serviceId, customerId, type, groupBookingId } = params.filter;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = 'bookingId';
        } else if (field === 'service.serviceId') {
            field = 'serviceTitle';
        } else if (field === 'customer.userId') {
            field = 'customerName';
        } else if (field === 'expert.userId') {
            field = 'expertName';
        } else if (field === 'service.ownerId') {
            field = 'ownerName';
        }
        const query = bookingId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  ids: bookingId,
                  serviceId: serviceId || undefined,
                  customerId: customerId || undefined,
                  status: status || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
                  type: type || undefined,
                  groupBookingId: groupBookingId || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  status: status || undefined,
                  serviceId: serviceId || undefined,
                  customerId: customerId || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
                  type: type || undefined,
                  groupBookingId: groupBookingId || undefined,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.bookings.map((booking: any) => ({
                                ...booking,
                                id: booking.bookingId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.bookingId } });
                })
                .catch(reject);
        });
    }

    getMany(params: DataProviderParams): Promise<any> {
        let idsQuery = '';
        for (const id of params.ids) {
            idsQuery = idsQuery.concat(idsQuery === '' ? id : `,${id}`);
        }

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?ids=${idsQuery}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.bookings.map((booking: any) => ({
                                ...booking,
                                id: booking.bookingId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    status: params.data.status,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }
}

export default new BookingDataProvider();
