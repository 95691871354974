import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    ChipField,
    Filter,
    SearchInput,
    SelectInput,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import { PageResults } from './../../pageResults/PageResults';

export const CallLogList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        perPage={25}
        pagination={<PageResults />}
        filters={
            <Filter>
                <SearchInput placeholder="Booking ID" source="bookingId" alwaysOn />
                <SearchInput placeholder="From" source="from" alwaysOn />
                <SearchInput placeholder="To" source="to" alwaysOn />
                <SelectInput
                    source="status"
                    choices={[
                        { id: 'CALLING', name: 'CALLING' },
                        { id: 'CALL_ANSWERED', name: 'CALL_ANSWERED' },
                        { id: 'CALL_REJECTED', name: 'CALL_REJECTED' },
                        { id: 'CALL_CUT_BEFORE_ANSWER', name: 'CALL_CUT_BEFORE_ANSWER' },
                        { id: 'CALL_ENDED', name: 'CALL_ENDED' },
                        { id: 'ENDED_BY_SERVER', name: 'ENDED_BY_SERVER' },
                        { id: 'ENDED_BY_APP', name: 'ENDED_BY_APP' },
                        { id: 'ENTERED_GROUP_CALL', name: 'ENTERED_GROUP_CALL' },
                        { id: 'GROUP_CALL_ENDED', name: 'GROUP_CALL_ENDED' },
                        { id: 'GROUP_CALL_STARTED', name: 'GROUP_CALL_STARTED' },
                        { id: 'GROUP_CALL_NOT_ATTENDED', name: 'GROUP_CALL_NOT_ATTENDED' },
                    ]}
                    alwaysOn
                />
                <SearchInput placeholder="" source="callLogId" label="Call ID" />
            </Filter>
        }
        sort={{ field: 'startTime', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="callLogId" label="ID" />
            <ReferenceField label="From" source="from" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <ReferenceField label="To" source="to" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <ReferenceField label="Ended by" source="callEndedBy" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <ReferenceField
                label="Booking"
                source="bookingId"
                reference={ResourceName.bookings}
                link="show"
                sortable={false}
            >
                <TextField source="bookingId" />
            </ReferenceField>
            <ChipField source="status" sortable={false} />
            <TextField source="fromDeviceId" sortable={false} />
            <TextField source="toDeviceId" sortable={false} />
            <TextField source="fromAppVersion" sortable={false} />
            <TextField source="toAppVersion" sortable={false} />
            <DateField source="startTime" showTime={true} />
            <DateField source="endTime" showTime={true} />
        </Datagrid>
    </List>
);
