import React from 'react';
import { List, Datagrid, TextField, Filter, SearchInput, NumberField } from 'react-admin';
import { PageResults } from './../../pageResults/PageResults';

export const CreditList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        pagination={<PageResults />}
        perPage={25}
        filters={
            <Filter>
                <SearchInput placeholder="User ID" source="userId" alwaysOn />
            </Filter>
        }
        sort={{ field: 'userId', order: 'ASC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="userId" label="UserId" />
            <TextField source="email" label="Email" />
            <NumberField source="customerProfile.creditBalance" label="Free Credits" />
            <NumberField source="customerProfile.creditUsed" label="Amount Used" />
        </Datagrid>
    </List>
);
