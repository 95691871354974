import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { API_URL } from '../../config/config';

class ForgotDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = 'auth/local/forgot-password-email';
    }

    getOne(params: DataProviderParams): Promise<any> {
        return Promise.resolve({ data: { id: params?.id, email: params?.id } });
    }

    getMany(params: DataProviderParams): Promise<any> {
        return Promise.resolve(
            params?.ids?.length > 0
                ? { data: [{ id: params.ids[0], email: params.ids[0] }], total: 1 }
                : { data: [], total: 0 },
        );
    }

    update(params: DataProviderParams): Promise<any> {
        const baseUrl = API_URL.replace(/\/admin$/, '');
        return new Promise((resolve, reject) => {
            this.getHttpClient(
                '',
                {
                    method: 'POST',
                    body: JSON.stringify({ email: params?.data?.email }),
                },
                baseUrl,
            )
                .then(({ json }: any) => {
                    resolve({ data: { id: params?.data?.email, email: params?.data?.email } });
                })
                .catch(reject);
        });
    }
}

export default new ForgotDataProvider();
