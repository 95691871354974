import { fetchUtils } from 'react-admin';
import { API_URL } from '../config/config';
import { CookieStorage } from '../storage/cookieStorage';
import jwt_decode from 'jwt-decode';

const httpClient = fetchUtils.fetchJson;

type LoginProps = {
    username: string;
    password: string;
};

type CheckErrorData = {
    status: number;
};

interface AuthPayload {
    sub: string;
    type: string;
    role: string;
}

export const authCookie = new CookieStorage('token');

export default {
    login: ({ username, password }: LoginProps) => {
        return new Promise((resolve, reject) => {
            httpClient(`${API_URL}/auth/login`, {
                method: 'POST',
                body: JSON.stringify({ username, password }),
            })
                .then(({ json }: any) => {
                    authCookie.set(json.token, 3600 * 2);
                    resolve();
                })
                .catch(reject);
        });
    },
    logout: () => {
        authCookie.remove();
        return Promise.resolve();
    },

    checkError: ({ status }: CheckErrorData) => {
        if (status === 401 || status === 403) {
            authCookie.remove();
            return Promise.reject();
        }
        return Promise.resolve();
    },

    checkAuth: () => {
        return authCookie.get() ? Promise.resolve() : Promise.reject();
    },

    getPermissions: () => {
        const token = authCookie.get();
        if (token) {
            const payload: AuthPayload = jwt_decode(token);
            return Promise.resolve(payload?.role ? [payload?.role] : []);
        } else {
            return Promise.resolve([]);
        }
    },

    getCurrentId: () => {
        const token = authCookie.get();
        if (token) {
            const payload: AuthPayload = jwt_decode(token);
            return payload.sub ? payload.sub : undefined;
        } else {
            return undefined;
        }
    },
};
