import React from 'react';
import _ from 'lodash';
import { AWS_URL } from '../config/config';

const S3ImageField = ({ source, record = {} }: { source: string; record?: any; label?: string }) => {
    const s3UrlKey = _.get(record, source);
    const url = AWS_URL + s3UrlKey;
    return <a href={url}>{s3UrlKey}</a>;
};

S3ImageField.defaultProps = {
    addLabel: true,
};

export default S3ImageField;
