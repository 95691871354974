import React from 'react';
import { List, Datagrid, TextField, ChipField, DateField, Filter, SearchInput } from 'react-admin';
import { PageResults } from '../../pageResults/PageResults';

export const AppList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        pagination={<PageResults />}
        perPage={25}
        filters={
            <Filter>
                <SearchInput placeholder="App ID" source="appId" alwaysOn />
            </Filter>
        }
        sort={{ field: 'createdDate', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="settingsId" />
            <TextField source="appId" />
            <TextField source="appName" sortable={false} />
            <ChipField source="platform" sortable={false} />
            <ChipField source="className" sortable={false} />
            <DateField source="createdDate" sortable={false} />
        </Datagrid>
    </List>
);
