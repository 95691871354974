import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    Filter,
    SearchInput,
    ChipField,
    NumberField,
    SelectInput,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import { PageResults } from './../../pageResults/PageResults';

export const NotificationList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        perPage={25}
        pagination={<PageResults />}
        filters={
            <Filter>
                <SearchInput placeholder="User ID" source="userId" alwaysOn />
                <SelectInput
                    source="status"
                    choices={[
                        { id: 'READ', name: 'READ' },
                        { id: 'UNREAD', name: 'UNREAD' },
                    ]}
                    alwaysOn
                />
            </Filter>
        }
        sort={{ field: 'createdDate', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="id" label="ID" sortable={false} />
            <ChipField source="status" label="Status" sortable={false} />
            <ReferenceField label="User" source="user.userId" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <NumberField source="ttlMs" label="TTL(ms)" sortable={false} />
            <DateField source="createdDate" showTime={true} />
        </Datagrid>
    </List>
);
