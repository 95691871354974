import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';
class UserDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.users;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params?.pagination || { page: 1, perPage: 10 };
        const { userId, email, isOwner, isExpert, name } = params.filter;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = 'userId';
        }
        const query = userId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  ids: userId,
                  email: email || undefined,
                  isOwner: isOwner || undefined,
                  isExpert: isExpert || undefined,
                  name: name || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  email: email || undefined,
                  isOwner: isOwner || undefined,
                  isExpert: isExpert || undefined,
                  name: name || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.users.map((user: any) => ({
                                ...user,
                                id: user.userId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    if (json?.ownerProfile?.secondaryCategories?.length === 0) {
                        resolve({ data: { ...json, id: json.userId } });
                    } else {
                        resolve({
                            data: {
                                ...json,
                                id: json.userId,
                                secondaryCategoryKeys: json?.ownerProfile?.secondaryCategories?.map(
                                    (category: any) => category?.key,
                                ),
                            },
                        });
                    }
                })
                .catch(reject);
        });
    }

    getMany(params: DataProviderParams): Promise<any> {
        let idsQuery = '';
        for (const id of params.ids) {
            idsQuery = idsQuery.concat(idsQuery === '' ? id : `,${id}`);
        }

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?ids=${idsQuery}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.users.map((user: any) => ({
                                ...user,
                                id: user.userId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    create(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient('', {
                method: 'POST',
                body: JSON.stringify(params.data),
            })
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.userId } });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    isOwner: params?.data?.isOwner,
                    isExpert: params?.data?.isExpert,
                    secondaryCategoryKeys: params?.data?.secondaryCategoryKeys,
                    categoryKey: params?.data?.ownerProfile?.category?.key,
                    ownerType: params?.data?.ownerProfile?.type,
                    status: params?.data?.status,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }

    delete(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'DELETE',
                body: JSON.stringify({
                    userName: params.previousData.username,
                    password: params.previousData.password,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }
}

export default new UserDataProvider();
