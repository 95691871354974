import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { stringify } from 'query-string';

class NotificationDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = 'push-notifications';
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { userId, status } = params.filter;
        let { field, order } = params.sort;
        if (field === 'user.userId') {
            field = 'userName';
        }

        const query = {
            skip: (page - 1) * perPage,
            take: perPage,
            userId,
            status: status || undefined,
            sortKey: field || undefined,
            sortOrder: order || undefined,
        };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.notifications.map((notification: any) => ({
                                ...notification,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json } });
                })
                .catch(reject);
        });
    }

    create(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            let data = params.data.data;
            try {
                data = JSON.parse(params.data.data);
            } catch (error) {}

            this.getHttpClient('', {
                method: 'POST',
                body: JSON.stringify({
                    userId: params.data.userId,
                    preset: params.data.preset,
                    notification: { title: params.data.notificationTitle, body: params.data.notificationBody },
                    data: data || {},
                    ttl: params.data.ttl || undefined,
                    sound: params.data.sound || undefined,
                }),
            })
                .then(({ json }: any) => {
                    resolve({ data: { ...json } });
                })
                .catch(reject);
        });
    }
}

export default new NotificationDataProvider();
