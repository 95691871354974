import React from 'react';
import { List, Datagrid, TextField, BooleanField, DateField, Filter, SearchInput, FunctionField } from 'react-admin';
import { PageResults } from './../../pageResults/PageResults';

export const ExpertList = (props: any) => (
    <List
        {...props}
        exporter={false}
        bulkActionButtons={false}
        hasCreate={false}
        perPage={25}
        pagination={<PageResults />}
        filters={
            <Filter>
                <SearchInput placeholder="User ID" source="userId" alwaysOn />
                <SearchInput placeholder="Name" source="name" alwaysOn />
            </Filter>
        }
        sort={{ field: 'userId', order: 'DESC' }}
    >
        <Datagrid rowClick="show">
            <TextField source="userId" label="UserId" />
            <TextField source="expertProfile.name" label="Name" sortable={false} />
            <BooleanField source="expertProfile.online" label="Online Status" sortable={false} />
            <BooleanField source="expertProfile.featured" label="Trending" sortable={false} />
            <TextField source="createdServicesCount" label="Created Services Count" sortable={false} />
            <FunctionField
                label="Completed Bookings Count"
                render={(record: any) =>
                    `${record.completedBookingsCount > 0 ? record.expertProfile.completedBookingsCount : 'None'}`
                }
                sortable={false}
            />
            <FunctionField
                label="Pending Bookings Count"
                render={(record: any) =>
                    `${record.pendingBookingsCount > 0 ? record.expertProfile.pendingBookingsCount : 'None'}`
                }
                sortable={false}
            />
            <DateField source="expertProfile.lastSignedupDate" label="Last Signedup Date" showTime sortable={false} />
        </Datagrid>
    </List>
);
