import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class ExpertApplicationDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.expertApplications;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { applicationId, status } = params.filter;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = 'applicationId';
        } else if (field === 'user.userId') {
            field = 'userName';
        } else if (field === 'category.key') {
            field = 'categoryDisplayName';
        }
        const query = applicationId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  ids: applicationId,
                  status: status || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  status: status || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.expertApplications.map((expertApplications: any) => ({
                                ...expertApplications,
                                id: expertApplications.applicationId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.applicationId } });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    status: params.data.status,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }
}
export default new ExpertApplicationDataProvider();
