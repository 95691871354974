import React from 'react';
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin';
import { ResourceName } from '../../config/config';

const redirect = () => `/${ResourceName.services}`;

const RejectEditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton label="Reject" />
    </Toolbar>
);

export const RejectServiceEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm toolbar={<RejectEditToolbar />} redirect={redirect}>
            <TextInput type="text" source="id" label="Service ID" />
            <TextInput type="text" source="rejectReason" label="Reason" multiline />
        </SimpleForm>
    </Edit>
);
