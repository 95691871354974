import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class PayoutDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.payouts;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { serviceIdFilter, type } = params.filter;
        let { field, order } = params.sort;
        if (field === 'ownerId') {
            field = 'ownerName';
        }

        const query = serviceIdFilter
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  ids: serviceIdFilter,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(type === 'pendings' ? `pending?${stringify(query)}` : `?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.payouts.map((payout: any) => ({
                                ...payout,
                                id: payout.payoutId ? payout.payoutId : payout.ownerId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    create(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            const startTimeMillis =
                params?.data?.startTime &&
                typeof params?.data?.startTime === 'object' &&
                !isNaN(params?.data?.startTime.getTime())
                    ? params?.data?.startTime.getTime()
                    : null;
            const endTimeMillis =
                params?.data?.endTime &&
                typeof params?.data?.endTime === 'object' &&
                !isNaN(params?.data?.endTime.getTime())
                    ? params?.data?.endTime.getTime()
                    : null;
            this.getHttpClient('', {
                method: 'POST',
                body: JSON.stringify({
                    ownerId: params?.data?.ownerId,
                    startTimeMillis,
                    endTimeMillis,
                }),
            })
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.payoutId } });
                })
                .catch(reject);
        });
    }
}

export default new PayoutDataProvider();
