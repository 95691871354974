import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'querystring';

class AppsDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.apps;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { appId } = params.filter;

        const query = appId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  appId: appId,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.apps.map((setting: any) => ({
                                ...setting,
                                id: setting.settingsId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.settingsId } });
                })
                .catch(reject);
        });
    }

    update(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    appId: params?.data?.appId,
                    appName: params?.data?.appName,
                    className: params?.data?.className,
                    platform: params?.data?.platform,
                    theme: params?.data?.theme,
                    meta: params?.data?.meta,
                    assets: params?.data?.assets,
                }),
            })
                .then((response: any) => {
                    resolve({ data: {} });
                })
                .catch(reject);
        });
    }

    create(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient('', {
                method: 'POST',
                body: JSON.stringify({
                    appId: params?.data?.appId,
                    appName: params?.data?.appName,
                    className: params?.data?.className,
                    platform: params?.data?.platform,
                    theme: params?.data?.theme || {},
                    meta: params?.data?.meta || {},
                    assets: params?.data?.assets || {},
                }),
            })
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.userId } });
                })
                .catch(reject);
        });
    }
}

export default new AppsDataProvider();
