import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';

class ExchangeRatesDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = 'currencies/' + ResourceName.exchangeRates;
    }

    getList(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient()
                .then(({ json }: any) => {
                    resolve({
                        data: json.exchangeRates || [],
                        total: json.exchangeRates?.length || 0,
                    });
                })
                .catch(reject);
        });
    }
}

export default new ExchangeRatesDataProvider();
