import * as React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    NumberField,
    ArrayField,
    Datagrid,
    DateField,
    ReferenceField,
} from 'react-admin';
import { ResourceName } from '../../config/config';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    row: {
        display: 'inline-block',
        width: '180px',
        marginRight: '30px',
    },
});

export const DashboardShow = (props: any) => {
    const classes = useStyles();
    return (
        <Show {...props}>
            <TabbedShowLayout>
                <Tab label="Overview">
                    <h4>Bookings</h4>
                    <NumberField className={classes.row} label="Total" source="overviewStats.totalBookings" />
                    <NumberField className={classes.row} label="Pending" source="overviewStats.totalPendingBookings" />
                    <NumberField
                        className={classes.row}
                        label="Pending With Calls"
                        source="overviewStats.totalPendingBookingsWithAtLeastOneCall"
                    />
                    <NumberField
                        className={classes.row}
                        label="Pending Without Calls"
                        source="overviewStats.totalPendingBookingsWithoutAtLeastOneCall"
                    />
                    <h4>Users</h4>
                    <NumberField className={classes.row} label="Total" source="overviewStats.totalUsers" />
                    <NumberField
                        className={classes.row}
                        label="Total Customers Only Users"
                        source="overviewStats.totalCustomersOnlyUsers"
                    />
                    <h4>Categories</h4>
                    <NumberField className={classes.row} label="Total" source="overviewStats.totalCategories" />
                    <NumberField
                        className={classes.row}
                        label="Total Active Categories"
                        source="overviewStats.totalActiveCategories"
                    />
                    <h4>Experts</h4>
                    <NumberField className={classes.row} label="Total Experts" source="overviewStats.totalExperts" />
                    <NumberField
                        className={classes.row}
                        label="Total Pending Review"
                        source="overviewStats.totalPendingReviewExperts"
                    />
                    <h4>Organisations</h4>
                    <NumberField className={classes.row} label="Total" source="overviewStats.totalOrganizations" />
                    <NumberField
                        className={classes.row}
                        label="Total Pending Review"
                        source="overviewStats.totalPendingReviewOrganizations"
                    />
                    <h4>Services</h4>
                    <NumberField className={classes.row} label="Total Services" source="overviewStats.totalServices" />
                    <NumberField
                        className={classes.row}
                        label="Total Active Services"
                        source="overviewStats.totalActiveServices"
                    />
                    <NumberField
                        className={classes.row}
                        label="Total Pending Approval"
                        source="overviewStats.totalPendingApprovalServices"
                    />
                    <NumberField
                        className={classes.row}
                        label="Total Inactive"
                        source="overviewStats.totalInactiveServices"
                    />
                </Tab>
                <Tab label="Pending Bookings" path="pending-bookings">
                    <ArrayField source="pendingBookings">
                        <Datagrid>
                            <ReferenceField
                                label="Booking"
                                source="bookingId"
                                reference={ResourceName.bookings}
                                link="show"
                            >
                                <TextField source="bookingId" />
                            </ReferenceField>
                            <ReferenceField label="Expert" source="bookingId" reference={ResourceName.bookings} link="">
                                <TextField source="expert.name" />
                            </ReferenceField>
                            <TextField source="notes" />
                            <TextField source="durationMinutes" />
                            <DateField source="createdDate" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Pending Organizations" path="pending-organizations">
                    <ArrayField source="reviewPendingOrganizations">
                        <Datagrid>
                            <ReferenceField label="User" source="userId" reference={ResourceName.users} link="show">
                                <TextField source="ownerProfile.name" />
                            </ReferenceField>
                            <TextField source="email" />
                            <TextField source="ownerProfile.profileId" label="Owner profile Id" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Pending Experts" path="pending-experts">
                    <ArrayField source="reviewPendingExperts">
                        <Datagrid>
                            <ReferenceField
                                label="Application Id"
                                source="applicationId"
                                reference={ResourceName.expertApplications}
                                link="show"
                            >
                                <TextField source="applicationId" />
                            </ReferenceField>
                            <TextField source="type" />
                            <ReferenceField label="User" source="userId" reference={ResourceName.users} link="show">
                                <TextField source="userId" />
                            </ReferenceField>
                            <DateField source="createdDate" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
                <Tab label="Pending Services" path="pending-services">
                    <ArrayField source="approvalPendingServices">
                        <Datagrid>
                            <ReferenceField
                                label="Service Id"
                                source="serviceId"
                                reference={ResourceName.services}
                                link="show"
                            >
                                <TextField source="serviceId" />
                            </ReferenceField>
                            <ReferenceField label="Owner" source="ownerId" reference={ResourceName.users} link="show">
                                <TextField source="ownerProfile.name" />
                            </ReferenceField>
                            <TextField source="title" />
                            <TextField source="details" />
                            <DateField source="createdDate" />
                        </Datagrid>
                    </ArrayField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
