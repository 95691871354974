import cookies from 'js-cookie';

export class CookieStorage {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    set(value: string, expirationSec?: number): void {
        cookies.set(
            this.name,
            value,
            expirationSec
                ? {
                      expires: new Date(new Date().getTime() + expirationSec * 1000),
                  }
                : undefined,
        );
    }

    get(): string | undefined {
        return cookies.get(this.name);
    }

    remove(): void {
        cookies.remove(this.name);
    }
}
