import React from 'react';
import { Create, SimpleForm, TextInput, required, NumberInput } from 'react-admin';

const creditValidation = (credits: number) => {
    if (!credits) {
        return 'The credit is required';
    }
    if (credits < 0) {
        return 'Minimum  credit is 0';
    }
    return 0;
};

export const AddCredits = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="userId" label="User ID" validate={[required()]} />
            <NumberInput
                source="credits"
                label="Credits"
                validate={[required(), creditValidation]}
            />
        </SimpleForm>
    </Create>
);
