import { Admin, Resource, Layout, MenuItemLink, usePermissions } from 'react-admin';
import { ResourceName, isProd, Role, isDemo } from './config/config';
import { BookingList } from './resource-views/bookings/BookingList';
import { BookingShow } from './resource-views/bookings/BookingShow';
import { CategoryCreate } from './resource-views/categories/CategoryCreate';
import { CategoryEdit } from './resource-views/categories/CategoryEdit';
import CategoryIcon from '@material-ui/icons/Category';
import { CategoryList } from './resource-views/categories/CategoryList';
import { CategoryShow } from './resource-views/categories/CategoryShow';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import React from 'react';
import { ServiceEdit } from './resource-views/services/ServiceEdit';
import { ServiceShow } from './resource-views/services/ServiceShow';
import { ServicesList } from './resource-views/services/ServiceList';
import { UserCreate } from './resource-views/users/UserCreate';
import { UserEdit } from './resource-views/users/UserEdit';
import UserIcon from '@material-ui/icons/People';
import { UserList } from './resource-views/users/UserList';
import { UserShow } from './resource-views/users/UserShow';
import authProvider from './auth/authProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import dataProvider from './data-provider/baseDataProvider';
import { environment } from './config/config';
import { CurrencyList } from './resource-views/currencies/CurrencyList';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { ExchangeRateList } from './resource-views/exchange-rates/ExchangeRateList';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { PayoutList } from './resource-views/payouts/PayoutList';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { PayoutCreate } from './resource-views/payouts/PayoutCreate';
import { PaymentShow } from './resource-views/payments/PaymentShow';
import CallIcon from '@material-ui/icons/Call';
import { CallLogList } from './resource-views/call-logs/CallLogList';
import { CallLogShow } from './resource-views/call-logs/CallLogShow';
import { DeviceList } from './resource-views/devices/DeviceList';
import { DeviceShow } from './resource-views/devices/DeviceShow';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import { ForgotEdit } from './resource-views/forgot/ForgotEdit';
import { RejectServiceEdit } from './resource-views/reject-service/rejectServiceEdit';
import { ReferralList } from './resource-views/referral_download/referralList';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ExpertApplicationsList } from './resource-views/expert-applications/ExpertApplicationsList';
import NoteIcon from '@material-ui/icons/Note';
import { ExpertApplicationsShow } from './resource-views/expert-applications/ExpertApplicationsShow';
import { ExpertApplicationsEdit } from './resource-views/expert-applications/ExpertApplicationsEdit';
import { NotificationList } from './resource-views/notifications/NotificationList';
import { NotificationShow } from './resource-views/notifications/NotificationShow';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { NotificationCreate } from './resource-views/notifications/NotificationCreate';
import DashboardIcon from '@material-ui/icons/DashboardSharp';
import { DashboardList } from './resource-views/dashboards/DashboardList';
import { DashboardShow } from './resource-views/dashboards/DashboardShow';
import { ChatList } from './resource-views/chats/ChatList';
import { ChatEdit } from './resource-views/chats/ChatEdit';
import ChatIcon from '@material-ui/icons/Chat';
import { CreditList } from './resource-views/credits/CreditList';
import { CreditShow } from './resource-views/credits/CreditShow';
import CreditIcon from '@material-ui/icons/Loyalty';
import { ExpertEdit } from './resource-views/experts/ExpertEdit';
import { ExpertList } from './resource-views/experts/ExpertList';
import ExpertIcon from '@material-ui/icons/Face';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { AdminList } from './resource-views/admins/AdminList';
import { AdminCreate } from './resource-views/admins/AdminCreate';
import { AdminEdit } from './resource-views/admins/AdminEdit';
import CustomAppBar from './components/CustomAppBar';
import customRoutes from './customRoutes';
import { BookingEdit } from './resource-views/bookings/BookingEdit';
import { AppConfigsList } from './resource-views/app-configs/AppConfigsList';
import { AppConfigCreate } from './resource-views/app-configs/AppConfigCreate';
import { ExpertShow } from './resource-views/experts/ExpertShow';
import { ServiceCreate } from './resource-views/services/ServiceCreate';
import { AppList } from './resource-views/apps/AppList';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import { AppShow } from './resource-views/apps/AppShow';
import { AppEdit } from './resource-views/apps/AppEdit';
import { AppCreate } from './resource-views/apps/AppCreate';
import { AddCredits } from './resource-views/credits/AddCredits';


const theme = createMuiTheme({
    palette: {
        type: isProd || isDemo ? 'light' : 'dark',
    },
    overrides: {
        MuiToolbar: {
            // override the styles of all instances of this component
            dense: {
                // Name of the rule
                backgroundColor: isProd || isDemo  ? '#2196f3' : '#1f285a',
            },
        },
    },
});

const Menu = () => {
    const { permissions } = usePermissions();


    return (
        <div>
            <MenuItemLink to={`/${ResourceName.dashboards}`} primaryText="Dashboard" leftIcon={<DashboardIcon />} />
            {permissions?.length && permissions[0] === Role.ROOT && (
                <MenuItemLink to={`/${ResourceName.admins}`} primaryText="Admins" leftIcon={<AccountCircleIcon />} />
            )}
            <MenuItemLink to={`/${ResourceName.users}`} primaryText="Users" leftIcon={<UserIcon />} />
            <MenuItemLink to={`/${ResourceName.experts}`} primaryText="Experts" leftIcon={<ExpertIcon />} />
            <MenuItemLink to={`/${ResourceName.services}`} primaryText="Services" leftIcon={<LocalOfferIcon />} />
            <MenuItemLink to={`/${ResourceName.bookings}`} primaryText="Bookings" leftIcon={<MenuBookIcon />} />
            <MenuItemLink to={`/${ResourceName.callLogs}`} primaryText="Calls" leftIcon={<CallIcon />} />
            <MenuItemLink to={`/${ResourceName.chats}`} primaryText="Messages" leftIcon={<ChatIcon />} />
            <MenuItemLink
                to={`/${ResourceName.appConfigs}`}
                primaryText="App Configs"
                leftIcon={<SettingsApplicationsIcon />}
            />
            <MenuItemLink
                to={`/${ResourceName.notifications}`}
                primaryText="Notifications"
                leftIcon={<NotificationsActiveIcon />}
            />
            <MenuItemLink to={`/${ResourceName.devices}`} primaryText="Devices" leftIcon={<DeviceUnknownIcon />} />
            <MenuItemLink
                to={`/${ResourceName.expertApplications}`}
                primaryText="Applications"
                leftIcon={<NoteIcon />}
            />
            <MenuItemLink to={`/${ResourceName.referrals}`} primaryText="Referrals" leftIcon={<GetAppIcon />} />
            <MenuItemLink to={`/${ResourceName.payouts}`} primaryText="Payouts" leftIcon={<CheckCircleOutlineIcon />} />
            <MenuItemLink to={`/${ResourceName.categories}`} primaryText="Categories" leftIcon={<CategoryIcon />} />
            <MenuItemLink to={`/${ResourceName.currencies}`} primaryText="Currencies" leftIcon={<AttachMoneyIcon />} />
            <MenuItemLink to={`/${ResourceName.credits}`} primaryText="Credits" leftIcon={<CreditIcon />} />
            <MenuItemLink to={`/${ResourceName.exchangeRates}`} primaryText="Forex" leftIcon={<SwapHorizIcon />} />
            <MenuItemLink to={`/${ResourceName.apps}`} primaryText="App" leftIcon={<PhonelinkSetupIcon />} />
        </div>
    );
};

const CustomLayout = (props: any) => <Layout {...props} appBar={CustomAppBar} />;

const App = () => (
    <Admin
        layout={CustomLayout}
        theme={theme}
        customRoutes={customRoutes}
        menu={Menu}
        title={`Bootup - Admin ${environment}`}
        dataProvider={dataProvider}
        authProvider={authProvider}
    >
        <Resource name={ResourceName.dashboards} list={DashboardList} show={DashboardShow} />
        <Resource name={ResourceName.admins} list={AdminList} create={AdminCreate} edit={AdminEdit} />
        <Resource name={ResourceName.users} list={UserList} show={UserShow} create={UserCreate} edit={UserEdit} />
        <Resource name={ResourceName.experts} list={ExpertList} show={ExpertShow} edit={ExpertEdit} icon={ExpertIcon} />
        <Resource name={ResourceName.services} list={ServicesList} show={ServiceShow} create={ServiceCreate} edit={ServiceEdit} />
        <Resource
            name={ResourceName.bookings}
            list={BookingList}
            show={BookingShow}
            edit={BookingEdit}
            icon={MenuBookIcon}
        />
        <Resource name={ResourceName.callLogs} list={CallLogList} show={CallLogShow} />
        <Resource name={ResourceName.chats} list={ChatList} edit={ChatEdit} />
        <Resource name={ResourceName.appConfigs} list={AppConfigsList} create={AppConfigCreate} />
        <Resource
            name={ResourceName.notifications}
            list={NotificationList}
            show={NotificationShow}
            create={NotificationCreate}
        />
        <Resource name={ResourceName.devices} list={DeviceList} show={DeviceShow} icon={DeviceUnknownIcon} />
        <Resource
            name={ResourceName.expertApplications}
            list={ExpertApplicationsList}
            show={ExpertApplicationsShow}
            edit={ExpertApplicationsEdit}
        />
        <Resource name={ResourceName.referrals} list={ReferralList} icon={GetAppIcon} />
        <Resource name={ResourceName.payouts} list={PayoutList} create={PayoutCreate} />
        <Resource name={ResourceName.pendingPayouts} />
        <Resource
            name={ResourceName.categories}
            list={CategoryList}
            show={CategoryShow}
            create={CategoryCreate}
            edit={CategoryEdit}
        />
        <Resource name={ResourceName.currencies} list={CurrencyList} />
        <Resource
            name={ResourceName.credits}
            list={CreditList}
            show={CreditShow}
            create={AddCredits} />
        <Resource name={ResourceName.exchangeRates} list={ExchangeRateList} />
        <Resource name={ResourceName.payments} show={PaymentShow} />
        <Resource name={ResourceName.forgot} edit={ForgotEdit} />
        <Resource name={ResourceName.rejectServices} edit={RejectServiceEdit} />
        <Resource name={ResourceName.apps} list={AppList} show={AppShow} edit={AppEdit} create={AppCreate}/>
    </Admin>
);

export default App;
