import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';

class CurrencyDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.currencies;
    }

    getList(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient()
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.currencies.map((currency: any) => ({
                                ...currency,
                                id: currency.code,
                            })) || [],
                        total: json.currencies?.length || 0,
                    });
                })
                .catch(reject);
        });
    }
}

export default new CurrencyDataProvider();
