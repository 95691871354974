import * as React from 'react';
import { FunctionField } from 'react-admin';

const AvailabilityField = ({ source, record = {} }: { source: string; record: any }) => (
    <div>
        <FunctionField
            multiline
            label="Availability"
            render={() => {
                const convertIntervalsToString = (intervals: { start: number; end: number }[]) => {
                    return intervals
                        .map((interval) => {
                            const startHour = Math.floor(interval.start / 60);
                            const startMin = Math.floor(interval.start % 60);
                            const endHour = Math.floor(interval.end / 60);
                            const endMin = Math.floor(interval.end % 60);
                            return `${startHour}:${startMin < 10 ? '0' + startMin : startMin} to ${endHour}:${
                                endMin < 10 ? '0' + endMin : endMin
                            }`;
                        })
                        .join(', ');
                };

                try {
                    const timezone = record[source]?.availability?.timezone || '';
                    const monday = convertIntervalsToString(record[source]?.availability?.monday || []);
                    const tuesday = convertIntervalsToString(record[source]?.availability?.tuesday || []);
                    const wednesday = convertIntervalsToString(record[source]?.availability?.wednesday || []);
                    const thursday = convertIntervalsToString(record[source]?.availability?.thursday || []);
                    const friday = convertIntervalsToString(record[source]?.availability?.friday || []);
                    const saturday = convertIntervalsToString(record[source]?.availability?.saturday || []);
                    const sunday = convertIntervalsToString(record[source]?.availability?.sunday || []);

                    return `Timezone: ${timezone},\n [ Monday: ${monday || '-'} ], \n\
                            [ Tuesday: ${tuesday || '-'} ], \n\
                            [ Wednesday: ${wednesday || '-'} ], \n\
                            [ Thursday: ${thursday || '-'} ], \n\
                            [ Friday: ${friday || '-'} ], \n\
                            [ Saturday: ${saturday || '-'} ], \n\
                            [ Sunday: ${sunday || '-'} ]`;
                } catch (error) {}
                return '';
            }}
        />
    </div>
);
AvailabilityField.defaultProps = {
    addLabel: true,
};
export default AvailabilityField;
