import React from 'react';
import { Show, TextField, SimpleShowLayout, ReferenceField, DateField } from 'react-admin';
import { ResourceName } from '../../config/config';

export const CallLogShow = (props: any) => (
    <Show {...props} hasEdit={false}>
        <SimpleShowLayout>
            <TextField source="callLogId" label="ID" />
            <ReferenceField label="From" source="from" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <ReferenceField label="To" source="to" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <ReferenceField label="Ended by" source="callEndedBy" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <ReferenceField label="Booking" source="bookingId" reference={ResourceName.bookings} link="show">
                <TextField source="bookingId" />
            </ReferenceField>
            <TextField source="status" />
            <TextField source="fromDeviceId" />
            <TextField source="toDeviceId" />
            <TextField source="fromAppVersion" />
            <TextField source="toAppVersion" />
            <DateField source="startTime" showTime={true} />
            <DateField source="endTime" showTime={true} />
        </SimpleShowLayout>
    </Show>
);
