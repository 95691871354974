import React from 'react';
import { SelectInput, Edit, SaveButton, SimpleForm, Toolbar, TextField } from 'react-admin';

const EditToolbar = (props: any) => (
    <Toolbar {...props}>
        <SaveButton label="Update Booking" />
    </Toolbar>
);

export const BookingEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm toolbar={<EditToolbar />}>
            <TextField source="status" label="Current Booking Status" />
            <SelectInput source="status" choices={[{ id: 'VOIDED', name: 'VOIDED' }]} />
        </SimpleForm>
    </Edit>
);
