import React from 'react';
import { Show, TextField, SimpleShowLayout, ReferenceField, DateField } from 'react-admin';
import { ResourceName } from '../../config/config';

export const DeviceShow = (props: any) => (
    <Show {...props} hasEdit={false}>
        <SimpleShowLayout>
            <ReferenceField label="User" source="userId" reference={ResourceName.users} link="show">
                <TextField source="customerProfile.name" />
            </ReferenceField>
            <TextField source="deviceUniqId" label="Device Unique Id" />
            <TextField source="deviceInfo" label="Device Info" />
            <TextField multiline source="pushToken" />
            <DateField source="createdDate" showTime={true} />
        </SimpleShowLayout>
    </Show>
);
