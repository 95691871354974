import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';

class RejectServicesDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = 'services';
    }

    getOne(params: DataProviderParams): Promise<any> {
        return Promise.resolve({ data: { id: params?.id } });
    }

    getMany(params: DataProviderParams): Promise<any> {
        return Promise.resolve(
            params?.ids?.length > 0
                ? { data: [{ id: params.ids[0], serviceId: params.ids[0] }], total: 1 }
                : { data: [], total: 0 },
        );
    }

    update(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}/change-request`, {
                method: 'POST',
                body: JSON.stringify({ rejectReason: params?.data?.rejectReason }),
            })
                .then(({ json }: any) => {
                    resolve({ data: { id: params?.data?.serviceId, rejectReason: params?.data?.rejectReason } });
                })
                .catch(reject);
        });
    }
}

export default new RejectServicesDataProvider();
