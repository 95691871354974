import { stringify } from 'query-string';
import { ResourceName } from '../../config/config';
import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';

class AppConfigsDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.appConfigs;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { configId } = params.filter;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = 'configId';
        }
        const query = configId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  configId: configId,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };
        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.configs.map((config: any) => ({
                                ...config,
                                id: config.configId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.configId } });
                })
                .catch(reject);
        });
    }

    getMany(params: DataProviderParams): Promise<any> {
        let configIdsQuery = '';
        for (const id of params.ids) {
            configIdsQuery = configIdsQuery.concat(configIdsQuery === '' ? id : `,${id}`);
        }

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?ids=${configIdsQuery}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.configs.map((config: any) => ({
                                ...config,
                                id: config.configId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    create(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient('', {
                method: 'POST',
                body: JSON.stringify(params.data),
            })
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.configId } });
                })
                .catch(reject);
        });
    }
}

export default new AppConfigsDataProvider();
