const processEnv = process.env.REACT_APP_ENV || 'development';

export const isProd = process.env.REACT_APP_ENV === 'production';

export const isDemo = process.env.REACT_APP_ENV === 'demo' || process.env.REACT_APP_ENV === 'bootup_stage';


export const environment = getEnvironment(processEnv).environment;
export const API_URL = getEnvironment(processEnv).API_URL;
export const AWS_URL = getEnvironment(processEnv).AWS_URL;
export const AWS_S3_CONSOLE_URL = getEnvironment(processEnv).AWS_S3_CONSOLE_URL;

interface EnvironmentData {
    environment: string;
    API_URL: string;
    AWS_URL: string;
    AWS_S3_CONSOLE_URL: string;
}

function getEnvironment(env: string): EnvironmentData {
    switch (env) {
        case 'production': {
            return {
                environment: 'Production environment',
                API_URL: 'https://api.gobootup.com/v1/admin',
                AWS_URL: 'https://bootup-demo.s3.eu-central-1.amazonaws.com/',
                AWS_S3_CONSOLE_URL: 'https://s3.console.aws.amazon.com/s3/buckets/bootup-demo/',
            };
        }

        case 'demo': {
            return {
                environment: 'Production environment',
                API_URL: 'https://api.gobootup.com/v1/admin',
                AWS_URL: 'https://bootup-demo.s3.eu-central-1.amazonaws.com/',
                AWS_S3_CONSOLE_URL: 'https://s3.console.aws.amazon.com/s3/buckets/bootup-demo/',
            };
        }
        case 'bootup_stage': {
            return {
                environment: 'Stage environment',
                API_URL: 'https://stage-api.gobootup.com/v1/admin',
                AWS_URL: 'https://bootup-stage.s3.ap-south-1.amazonaws.com/',
                AWS_S3_CONSOLE_URL: 'https://s3.console.aws.amazon.com/s3/buckets/bootup-stage/',
            };
        }
        case 'stage': {
            return {
                environment: 'Stage environment',
                API_URL: 'https://api.serw.io/v1/admin',
                AWS_URL: 'https://serwstage.s3.us-east-2.amazonaws.com/',
                AWS_S3_CONSOLE_URL: 'https://s3.console.aws.amazon.com/s3/buckets/serwstage/',
            };
        }
        default: {
            return {
                environment: 'Develop environment',
                API_URL: 'https://dev-api.expertrepublic.com/v1/admin',
                AWS_URL: 'https://serwdevelop.s3.us-east-2.amazonaws.com/',
                AWS_S3_CONSOLE_URL: 'https://s3.console.aws.amazon.com/s3/buckets/serwdevelop/',
            };
        }
    }
}

export enum ResourceName {
    services = 'services',
    users = 'users',
    bookings = 'bookings',
    categories = 'categories',
    currencies = 'currencies',
    exchangeRates = 'exchange-rates',
    payouts = 'payouts',
    pendingPayouts = 'pending-payouts',
    payments = 'payments',
    callLogs = 'call-logs',
    devices = 'devices',
    forgot = 'forgot',
    rejectServices = 'reject-services',
    referrals = 'referrals',
    expertApplications = 'expert-applications',
    notifications = 'notifications',
    dashboards = 'dashboards',
    chats = 'chats',
    credits = 'credits',
    experts = 'experts',
    admins = 'admins',
    appConfigs = 'app-configs',
    apps = 'apps',
}

export enum Role {
    ROOT = 'ROOT',
    ADMIN = 'ADMIN',
}
