import { BaseResourceDataProvider } from '../baseResourceDataProvider';
import { DataProviderParams } from '../DataProvider';
import { ResourceName } from '../../config/config';
import { stringify } from 'query-string';

class CallLogDataProvider extends BaseResourceDataProvider {
    constructor() {
        super();
        this.resourceName = ResourceName.callLogs;
    }

    getList(params: DataProviderParams): Promise<any> {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { callLogId, bookingId, from, to, status } = params.filter;
        let { field, order } = params.sort;
        if (field === 'id') {
            field = 'callLogId';
        } else if (field === 'to') {
            field = 'toUserName';
        } else if (field === 'from') {
            field = 'fromUserName';
        } else if (field === 'callEndedBy') {
            field = 'callEndedByUserName';
        }
        const query = callLogId
            ? {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  ids: callLogId,
                  bookingId: bookingId || undefined,
                  from: from || undefined,
                  to: to || undefined,
                  status: status || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              }
            : {
                  skip: (page - 1) * perPage,
                  take: perPage,
                  bookingId: bookingId || undefined,
                  from: from || undefined,
                  to: to || undefined,
                  status: status || undefined,
                  sortKey: field || undefined,
                  sortOrder: order || undefined,
              };

        return new Promise((resolve, reject) => {
            this.getHttpClient(`?${stringify(query)}`)
                .then(({ json }: any) => {
                    resolve({
                        data:
                            json.callLogs.map((callLog: any) => ({
                                ...callLog,
                                id: callLog.callLogId,
                            })) || [],
                        total: json.totalCount || 0,
                    });
                })
                .catch(reject);
        });
    }

    getOne(params: DataProviderParams): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getHttpClient(`${params.id}`)
                .then(({ json }: any) => {
                    resolve({ data: { ...json, id: json.callLogId } });
                })
                .catch(reject);
        });
    }
}

export default new CallLogDataProvider();
